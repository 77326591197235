<template>
	<v-main>	
		<v-toolbar 
			color="white" 
			flat
			dense
			v-if="curpage"
		>
			<v-btn 
				icon 
				light
				@click="startPage()"
			>
				<v-icon color="grey darken-2">mdi-arrow-left</v-icon>
			</v-btn>

			<v-toolbar-title class="grey--text text--darken-4">{{title}}</v-toolbar-title>
		</v-toolbar>		
		
		<v-list transition="slide-x-transition" v-show="curpage == null">
			<v-list-item
				v-for="(item, index) in items"
				:key="index"
				@click="showPage(index)"
			>
				<v-list-item-content>
					<v-list-item-title v-text="item.title"></v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
		<component v-bind:is="curpage"
			@start-page="startPage"
		></component>
	</v-main>	
</template>
<script>
import About from './info/About';
import Contact from './info/Contact';
import Policy from './info/Policy';
import Oferta from './info/Oferta';
export default {
	name: "Info",    
	components: {
		About,
		Contact,
		Policy,
		Oferta
	},	
	data() {
		return {	
			title:'',
			items:[				
				{title:'О компании', page:'About'},
				{title:'Контакты', page:'Contact'},
				{title:'Политика конфидециальности', page:'Policy'},
				{title:'Договор оферта', page:'Oferta'},
			],
			curpage:null,
		}
	},
	methods:{
		startPage(){
			this.curpage = null;
			this.title = 'Информация';
		},
		showPage(index){
			this.curpage = this.items[index].page;
			this.title = this.items[index].title;
		}
	},
	mounted(){
		this.startPage();
	}
}
</script>