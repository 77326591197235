<template>
	<v-main 
		v-scroll.self="onOrderScroll"
		class="overflow-y-auto"
		style="height: 100vh"	
	>
		<template v-if="orders == null">
			<v-skeleton-loader
				type="article"
			></v-skeleton-loader>
			<v-skeleton-loader
				type="actions"
			></v-skeleton-loader>
			<v-skeleton-loader			
				type="list-item-two-line"
			></v-skeleton-loader>		
			<v-skeleton-loader			
				type="list-item-two-line"			
			></v-skeleton-loader>		
			<v-skeleton-loader			
				type="list-item-two-line"			
			></v-skeleton-loader>		
			<v-skeleton-loader			
				type="list-item-two-line"			
			></v-skeleton-loader>		
		</template>
		<template v-else>
			<template v-if="orders.length == 0">
				<v-container>
					<v-alert 						
						type="info" 
						class="ma-0 body-2" 
						dense
						outlined
						prominent
						border="left" 	
					>										
						У вас нет еще ни одного заказа
					</v-alert>					
				</v-container>				
			</template>
			<template v-else>
				<v-card-text class="pa-0">
				<v-container>					
					<v-card>
						<v-simple-table dence>
							<template v-slot:default>
								<thead>
								<tr>
									<th class="text-left">Мойки</th>
									<th class="text-right">Кол-во</th>
									<th class="text-right">Сумма</th>
								</tr>
								</thead>
								<tbody>
								<tr>
									<td class="subtitle-2">Всего</td>
									<td class="text-right subtitle-2">{{total}}</td>
									<td class="text-right subtitle-2">{{totalsum}}</td>
								</tr>
								<tr>
									<td class="subtitle-2">Неоплаченные</td>
									<td class="text-right subtitle-2">{{notpayed}}</td>
									<td class="text-right subtitle-2">{{credit}}</td>
								</tr>					
								</tbody>
							</template>
						</v-simple-table>
						<template v-if="paycredit">
							<v-card-text>
								<v-alert 
									type="error" 
									class="ma-0 body-2" 
									dense
									outlined
									text	
								>
									У вас имеется долг в размере:<br/> <b>{{credit}}</b><br/>
									Чтобы продолжить пользоваться сервисом, вам необходимо погасить долг.
								</v-alert>
							</v-card-text>
							<v-divider></v-divider>
							<v-card-actions>
								<v-btn block							 	
									color="primary"
									depressed
									:loading="loadingpay"
									@click="payCredit()"
								>
									Оплатить долг
								</v-btn>
							</v-card-actions>
						</template>
					</v-card>
				</v-container>
				<v-list>	
					<v-subheader>История</v-subheader>
					<template				
						v-for="order in orders"
					>
						<v-divider
							:key="'divider'+order.id"
						></v-divider>
						<v-list-item
							:key="'order'+order.id"							
						>
							<v-list-item-avatar
								v-if="order.ticket"
							>
								<v-icon									
									dark
									class="orange"
								>
									mdi-ticket
								</v-icon>
							</v-list-item-avatar>						
							<v-list-item-content>
							<v-list-item-title>
								Заказ #{{order.id}} 
								<v-chip		
									v-if="order.coupon"
									color="orange"
									text-color="white"
									label
									x-small
									>
									купон
								</v-chip>
								<v-chip		
									v-if="order.paysystem == 4"
									color="orange"
									text-color="white"
									label
									x-small
									>
										<v-icon small left>mdi-ticket</v-icon> #{{order.payid}}
								</v-chip>								
							</v-list-item-title>
							<v-list-item-subtitle v-text="order.date"></v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-action>
								{{order.sum}}
								<v-chip
									v-if="order.canceled" 
									color="grey"
									text-color="white"
									label
									x-small
									>
									возвращен
								</v-chip>
								<v-chip
									v-else-if="order.payed" 
									color="green"
									text-color="white"
									label
									x-small
									>
									оплачен
								</v-chip>
								<v-chip
									v-else
									color="red"
									text-color="white"
									label
									x-small
									>
									не оплачен
								</v-chip>
							</v-list-item-action>					
						</v-list-item>		
						<v-simple-table dense :key="'table'+order.id" class="mb-3">
							<template v-slot:default>
							<tbody>
								<tr v-for="item in order.items" :key="item.name" class="caption">
								<td class="basket">{{ item.name }}</td>
								<td class="text-right basket">{{ item.quantity }} {{ item.measure_name }}</td>
								</tr>
							</tbody>
							</template>
						</v-simple-table>
					</template>
					<v-list-item v-if="!loaded">
						<v-progress-linear
							color="primary"
							indeterminate
							rounded
							height="6"
						></v-progress-linear>
					</v-list-item>
				</v-list>
				</v-card-text>	
			</template>
		</template>
	</v-main>
</template>
<script>
import axios from 'axios';
export default {
	name: "Orders",   
	props:['props'],	 
	data() {
		return {
			orders:null,
			total:0,
			totalsum:'',
			notpayed:0,
			credit:'',
			paycredit:false,
			loadingpay:false,
			page:0,
			loading:false,
			loaded:false,
		}
	},
	methods:{
		onOrderScroll(e){ 			
			if(e.target.scrollTop+e.target.offsetHeight>=e.target.firstChild.firstChild.offsetHeight){
				this.getOrders();
				//console.log('Конец');
			}      
		},		
		getOrders(){
			//console.log('Получаем список карт');
			if(!this.loading){
				this.loading = true;
				let params = new URLSearchParams();
				params.append('sessid', this.props.user.sessid);
				params.append('phone', this.props.user.phone);
				params.append('token', this.props.user.token);
				if(this.page>0){
					params.append('page', this.page+1);
				}			
				axios({
					method: 'post',
					headers: {'Content-Type': 'application/x-www-form-urlencoded'},
					url: this.props.apiUrl+'getorders.json',
					data:params,
					withCredentials: true
				})				
				.then(response => {				
					if(typeof response.data.result.items == 'object'){
						//console.log(response.data.result);												
						if(this.page==0){							
							this.orders = response.data.result.items;
							this.total = response.data.result.total;
							this.totalsum = response.data.result.totalsum;
							this.notpayed = response.data.result.notpayed;
							this.credit = response.data.result.credit;
							this.paycredit = response.data.result.paycredit;
							if(!this.paycredit){
								let user = {credit:0};
								this.$emit('event', {name:'saveUser', props:{user:user}});
							}
							this.page++;
							this.loading = false;
							if(response.data.result.items.length<20){
								this.loaded = true;
							}
						}else{
							if(response.data.result.items.length>0){
								this.orders = this.orders.concat(response.data.result.items);						
								this.page++;	
								this.loading = false;						
							}else{
								this.loaded = true;
							}
						}						
					}					
					//console.log(response.data.result.items);
				})
				.catch(error => {
					this.loading = false;
					console.log(error);
				});
			}
		},
		payCredit(){
			this.loadingpay = true;
			let params = new URLSearchParams();
			params.append('sessid', this.props.user.sessid);
			params.append('phone', this.props.user.phone);
			params.append('token', this.props.user.token);

			axios({
				method: 'post',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				url: this.props.apiUrl+'paycredit.json',
				data:params,
				withCredentials: true
			})				
			.then(response => {								
				//console.log(response.data.result);
				this.loadingpay = false;
				//this.getOrders();

				let errors = 0;
				let success = 0;
				let sum = 0;
				for(let i in response.data.result.orders){
					if(response.data.result.orders[i].payed){
						success++;
						sum+=response.data.result.orders[i].sum;
					}else{
						errors++;
					}
				}
				
				if(errors){					
					this.$emit('show-dialog', {title:'Ошибка', message: 'Некоторые заказы ('+errors+') не были оплачены. Всего олпачено '+success+' заказов. Списано '+sum+' руб.'});
				}else{
					this.$emit('show-dialog', {title:'Успешно', message: 'Все заказы были оплачены. Списано '+sum+' руб.'});										
				}	
				this.page = 0;			
				this.getOrders();
			})
			.catch(error => {
				console.log(error);
				this.loadingpay = false;
			});						
		}
	},
	mounted(){	
		this.getOrders();
	}
}
</script>
<style scoped>
	.v-data-table td.basket{
		font-size:12px;
	}
</style>