<template>
	<v-main>	
			
		<template v-if="props.user.cards>0">
			<v-skeleton-loader
				:loading="loading"
				type="list-item-avatar-two-line"
				transition="fade-transition"				
			>
				<v-list>	
					<template
						v-for="card in cards"							
					>
						<v-list-item
							:key="card.ID"
						>
							<v-list-item-icon>
								<v-icon
									:class="[card.CARD_TYPE]"						
								></v-icon>
							</v-list-item-icon>

							<v-list-item-content>
							<v-list-item-title v-text="card.NUMBER"></v-list-item-title>
							<v-list-item-subtitle v-text="card.MMYY"></v-list-item-subtitle>
							</v-list-item-content>

							<v-list-item-action>
							<v-btn icon v-if="showdelete" @click="deleteCard(card.ID)" :loading="deleteloading==card.ID">
								<v-icon color="red">mdi-close-circle</v-icon>
							</v-btn>						
							<template v-else>
								<v-btn icon v-if="card.SORT != 1" @click="setCardMain(card.ID)" :loading="setmainloading==card.ID">
									<v-icon color="grey">mdi-checkbox-blank-circle</v-icon>
								</v-btn>
								<v-btn icon v-else >
									<v-icon color="primary">mdi-checkbox-marked-circle</v-icon>
								</v-btn>
							</template>
							</v-list-item-action>
						</v-list-item>		
						<v-divider
							:key="'dev'+card.ID"
						></v-divider>
					</template>
				</v-list>
			</v-skeleton-loader>
		</template>					
		<v-container v-else>			
			<v-alert 
				type="info" 
				class="ma-0 body-2" 
				dense
				outlined
				prominent
				border="left" 	
			>
				У вас нет еще ни одной карты. Чтобы включить мойку неоходимо привязать банковскую карту.
			</v-alert>	
			<v-banner two-line
			>
				<v-avatar
					slot="icon"
					color="green"
					size="40"
				>
					<v-icon color="white">mdi-check</v-icon>
				</v-avatar>
				<h3 class="mb-4">Принимаем</h3>
				
				<img  src="./../assets/pay_icons.png" />				
			</v-banner>				
			<v-banner two-line
			>
				<v-avatar
					slot="icon"
					color="red"
					size="40"
				>
					<v-icon color="white">mdi-cancel</v-icon>
				</v-avatar>
				<h3 class="mb-4">Не принимаем!</h3>
				<img class="mb-4" src="./../assets/no_pay_icons.png" />
				<div class="text-caption">Карты Maestro и Visa Electron не принимаются, так как не поддерживают технологию рекурентных платежей</div>
			</v-banner>					
		</v-container>	
		<v-footer
			app
			color="white"
			elevation=4			
		>
			<v-btn color="primary"				
				depressed
				@click="addCard()"
			>
			Добавить карту
			</v-btn>
			<v-spacer></v-spacer>
			<v-btn v-if="showdelete" text				
				depressed
				@click="showdelete = false"
			>		
			Готово
			</v-btn>				
			<v-btn v-else text
				depressed
				class="pa-2"
				@click="showdelete = true"
			>
			Изменить
			</v-btn>			
		</v-footer>		
		<v-dialog 
			v-model="addcard" 
			fullscreen
			transition="slide-x-reverse-transition"   
		>
		<v-card tile class="tinkoff">
			<v-toolbar dark dense color="primary">
				<v-toolbar-title>Добавить карту</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn icon dark @click="closeAddCard()">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>		
			<iframe :src="payurl"></iframe>								
		</v-card>
		</v-dialog>				
	</v-main>
</template>
<script>
import axios from 'axios';
export default {
	name: "Cards",    
	props:['props'],
	data() {
		return {
			loading:true,
			cards:[],
			addcard:false,
			payurl:null,
			setmainloading:null,
			showdelete:false,
			deleteloading:null
		};
	},
	methods:{
		addCard(){    			
			if(this.payurl == null){
				let params = new URLSearchParams();
				params.append('sessid', this.props.user.sessid);
				params.append('phone', this.props.user.phone);
				params.append('token', this.props.user.token);
				axios({
					method: 'post',
					headers: {'Content-Type': 'application/x-www-form-urlencoded'},
					url: this.props.apiUrl+'addcard.json',
					data:params,
					withCredentials: true
				})			
				.then(response => {
					if(response.data.error != null){
						console.log(response.data.error);						
					}else{
						this.payurl = response.data.result.payurl;
						this.addcard=true;
					}
					console.log(response.data);
				})
				.catch(error => {
					console.log(error);
				});			
			}else{
				this.addcard=true;
			}
		},
		getCards(){
			let params = new URLSearchParams();
			params.append('sessid', this.props.user.sessid);
			params.append('phone', this.props.user.phone);
			params.append('token', this.props.user.token);
			axios({
				method: 'post',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				url: this.props.apiUrl+'getcards.json',
				data:params,
				withCredentials: true
			})				
			.then(response => {
				this.loading = false;
				this.cards = response.data.result;				
				this.$emit('event', {name:'saveUser', props:{user:{cards:this.cards.length}}});
				console.log(response.data);
			})
			.catch(error => {
				console.log(error);
			});
		},
		setCardMain(cardId){
			if(cardId>0){
				this.setmainloading = cardId;
				let params = new URLSearchParams();
				params.append('card_id', cardId);
				params.append('sessid', this.props.user.sessid);
				params.append('phone', this.props.user.phone);
				params.append('token', this.props.user.token);

				axios({
					method: 'post',
					headers: {'Content-Type': 'application/x-www-form-urlencoded', 'set-cookie':'PHPSESSID='+this.props.user.sessid},
					url: this.props.apiUrl+'setcardmain.json',
					data:params,
					withCredentials: true
				})				
				.then(response => {					
					if(response.data.error != null){
						console.log(response.data.error);						
					}else{
						for(let i in this.cards){
							if(this.cards[i].ID == cardId){
								this.cards[i].SORT = 1;
							}else{
								this.cards[i].SORT = 100;
							}
						}
					}
					this.setmainloading = null;
				})
				.catch(error => {
					console.log(error);
					this.setmainloading = null;
				});
			}
		},
		deleteCard(cardId){
			if(cardId>0){
				this.deleteloading = cardId;
				let params = new URLSearchParams();
				params.append('card_id', cardId);
				params.append('sessid', this.props.user.sessid);
				params.append('phone', this.props.user.phone);
				params.append('token', this.props.user.token);

				axios({
					method: 'post',
					headers: {'Content-Type': 'application/x-www-form-urlencoded', 'set-cookie':'PHPSESSID='+this.props.user.sessid},
					url: this.props.apiUrl+'deletecard.json',
					data:params,
					withCredentials: true
				})				
				.then(response => {					
					if(response.data.error != null){
						console.log(response.data.error);						
					}else{
						for(let i in this.cards){
							if(this.cards[i].ID == cardId){
								let needsetmain = false;
								if(this.cards[i].SORT == 1){
									needsetmain = true;
								}
								this.cards.splice(i,1);
								if(this.cards.length>0 && needsetmain){
									this.cards[0].SORT = 1;									
								}
								this.$emit('event', {name:'saveUser', props:{user:{cards:this.cards.length}}});								
							}
						}
						
					}
					this.deleteloading = null;
				})
				.catch(error => {
					console.log(error);
					this.deleteloading = null;
				});
			}			
		},
		closeAddCard(){
			this.payurl == null;
			this.getCards();
			this.addcard = false;
		},
		resetAddCard(){
			this.payurl == null;
		}
	},
	mounted(){	
		console.log('Cards: '+this.props.user);	
		this.getCards();
	}  	
}
</script>
<style scoped>
	.tinkoff{
		position:fixed;
		top:0;
		bottom:0;
	}
	iframe{
		width:100%;
		height:100%;
		padding:0;
		margin:0;
		border:none;
	}
	.MASTERCARD{
		background:url(./../assets/pay_icons.png) 0 0;
		width:33px;
		height:22px;
	}
	.VISA{
		background:url(./../assets/pay_icons.png) -52px 0;
		width:33px;
		height:22px;
	}	
	.MIR{
		background:url(./../assets/pay_icons.png) -107px 0;
		width:33px;
		height:22px;
	}	
</style>