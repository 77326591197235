<template>
	<v-main>
		<template v-if="false">
			<v-skeleton-loader			
				type="list-item-two-line"
			></v-skeleton-loader>		
			<v-skeleton-loader			
				type="list-item-two-line"			
			></v-skeleton-loader>		
			<v-skeleton-loader			
				type="list-item-two-line"			
			></v-skeleton-loader>		
			<v-skeleton-loader			
				type="list-item-two-line"			
			></v-skeleton-loader>		
		</template>
		<template v-else>
			<v-container v-for="(wash, code) in washes" 
				:key="code"
			>
				<v-row dense>										
					<v-col v-for="(box, index) in wash.BOXES" cols="12"
						:key="index"
					>			
						<v-card
							@click="selectDevice(box)"
						>
							<v-card-actions>								
								<v-icon large>mdi-numeric-{{box.SORT}}-circle-outline</v-icon>
								<v-chip
									v-if="orders[box.XML_ID] > 0"
									class="ml-4"
									color="black"
									label		
									dark						
								>	
								{{digits[box.XML_ID]}}
								</v-chip>						
								<v-spacer></v-spacer>
								<template v-if="check[box.XML_ID] > 0">
									<v-chip
										color="green"
										class="mr-4"
										label
										dark
									>
										{{check[box.XML_ID]}} руб.
									</v-chip>
									<v-btn 										
										@click.stop="pay(box.XML_ID)"
										color="primary"
										depressed
										small
									>
										Оплачено
									</v-btn>									
								</template>								
								<template v-else-if="(selected == box.XML_ID &&  box.ONLINE == 1) || orders[box.XML_ID] !=0">									
									<template v-if="orders[box.XML_ID] == 0">
										<v-btn 
											@click.stop="unselectDevice()"
											depressed
											small
										>
											Отмена
										</v-btn>
										<v-btn
											@click.stop="startDevice(box.XML_ID)"
											color="primary"
											depressed
											small
										>
											Включить
										</v-btn>
									</template>
									<v-btn v-else-if="orders[box.XML_ID] == -1"
										color="primary"
										depressed 
										loading
										small
									>
										Включаем
									</v-btn>
									<template v-else>										
										<v-btn
											@click="stopDevice(box.XML_ID)"
											color="error"
											depressed	
											small
										>
											Выключить
										</v-btn>
									</template>
								</template>
								<template v-else>
									<v-chip
										label
										:disabled="box.ONLINE != 1"
										:color="box.ONLINE == 1 ? 'primary':''"
									>
										<template v-if="box.ONLINE==null">
											<v-icon class="icon-loading">mdi-loading</v-icon>
											Обновляем
										</template>
										<template v-else-if="box.ONLINE==0">
											<v-icon >mdi-flash-off</v-icon>
											Не в сети
										</template>
										<template  v-else-if="box.ONLINE==2">
											<v-icon>mdi-car-wash</v-icon>
											Занят
										</template>
										<template v-else>
											Готов
										</template>
									</v-chip>
								</template>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</template>
	</v-main>
</template>
<script>
import axios from 'axios';
export default {
	name: "Kassa",   
	props:['props'],	 
	data() {
		return {
			washCode:this.props.user.info.kassa,
			washes:[],
			selected:null,			
			orders:{},
			timers:{},			
			counters:{},
			check:{},
		}
	},
	/*watch:{
		timers:{
			handler: function (val) {
				console.log(val);
				for(let i in val){
					let minutes = Math.floor(val[i]/60);
					let seconds = val[i]%60;
					console.log(this.zeroPadding(minutes, 2) + ':' + this.zeroPadding(seconds, 2));
				}
			},
			deep: true
		}
	},*/
	computed:{
		digits(){
			let digits = {};
			for(let i in this.timers){
				let minutes = Math.floor(this.timers[i]/60);
				let seconds = this.timers[i]%60;
				digits[i] = this.zeroPadding(minutes, 2) + ':' + this.zeroPadding(seconds, 2);
				//console.log(this.zeroPadding(minutes, 2) + ':' + this.zeroPadding(seconds, 2));
			}	
			return digits;	
		}
	},
	methods:{
		zeroPadding(num, digit) {
			var zero = '';
			for(var i = 0; i < digit; i++) {
				zero += '0';
			}
			return (zero + num).slice(-digit);
		},
		loadWashes(){
			if(this.washCode){
				axios
				.get(this.props.apiUrl+'getdevices.json?wash_code='+this.washCode)
				.then(response => {
					if(response.data.result){					
						this.washes = response.data.result;
						for(let i in this.washes[this.washCode].BOXES){    
							//this.washes[this.washCode].BOXES[i].ONLINE = null;						
							//Vue.set(this.orders, this.washes[this.washCode].BOXES[i].XML_ID, 0);
							let box = this.washes[this.washCode].BOXES[i];
							this.$set(this.orders, box.XML_ID, 0);
							this.$set(this.timers, box.XML_ID, 0);
							this.$set(this.check, box.XML_ID, 0);
							this.$set(this.counters, box.XML_ID, 0);
							//this.orders[this.washes[this.washCode].BOXES[i].XML_ID] = 0;
							this.pingDevices(box);						
							//this.washes[this.washCode].BOXES[i].ONLINE = this.pingDevices(this.washes[this.washCode].BOXES[i].XML_ID);						
						}					
					}else{
						console.log(response.data);
					}
					//console.log(this.washes);				
				})
				.catch(error => {
					console.log(error);
				});		
			}
		},
		pingDevices(box){
			axios
			.get(this.props.apiUrl+'ping.json?id='+box.XML_ID, {timeout: 5000})
			.then(response => {				
				box.ONLINE = response.data.result;
				//box.ONLINE = 0;
			})
			.catch(error => {
				box.ONLINE = 0;	
				console.log(error);			
			});
		},
		selectDevice(box){					
			if(this.selected != box.XML_ID){	
				this.selected = box.XML_ID;
				box.ONLINE = null;
				this.pingDevices(box);
			}
		},
		unselectDevice(){
			this.selected = null;			
		},
		startDevice(device_id){
			//console.log('Перед включением '+ device_id);
			//console.log(this.orders);			
			if(this.orders[device_id] == 0){				
				this.orders[device_id] = -1;					
				let params = new URLSearchParams();
				params.append('deviceid', device_id);
				params.append('sessid', this.props.user.sessid);
				params.append('phone', this.props.user.phone);
				params.append('token', this.props.user.token);
				axios({
					method: 'post',
					headers: {'Content-Type': 'application/x-www-form-urlencoded'},
					url: this.props.apiUrl+'startdevice.json',
					data:params,
					withCredentials: true
				})			
				.then(response => {
					if(response.data.error != null){
						this.orders[device_id] = 0;
						this.$emit('show-dialog', {title:'Ошибка', message: response.data.error.message});
						//this.pingDevices(this.currentWash);
						console.log(response.data);              
					}
					else{
						this.orders[device_id] = response.data.result.orderid;
						this.counters[device_id] = setInterval(() => {
							this.timers[device_id]++;
						}, 1000);							
						//this.orders[device_id]= response.data.result.orderid;						
					}					
				})
				.catch(error => {
					this.orders[device_id] == 0;
					console.log('Catch:' + error);
				});
				//this.pingDevices(this.currentWash); 
			}			
		},
		stopDevice(device_id){
			if(this.orders[device_id] > 0){
				let orderId = this.orders[device_id];
				this.orders[device_id] = -1;
				let params = new URLSearchParams();
				params.append('deviceid', device_id);				
				params.append('orderid', orderId);
				params.append('sessid', this.props.user.sessid);
				params.append('phone', this.props.user.phone);
				params.append('token', this.props.user.token);
				axios({
					method: 'post',
					headers: {'Content-Type': 'application/x-www-form-urlencoded'},
					url: this.props.apiUrl+'stopdevice.json',
					data:params,
					withCredentials: true
				})			
				.then(response => {					
					if(response.data.error != null){						
						this.orders[device_id] = orderId;
						if(response.data.error.code == -214){
							this.orders[device_id] = 0;
						}		
						this.$emit('show-dialog', {title:'Ошибка', message: response.data.error.message});
						console.log(response.data);              
					}
					else{	
						this.orders[device_id] = 0;					
						this.check[device_id] = response.data.result.payment.result.sum;
						clearInterval(this.counters[device_id]);
						this.timers[device_id] = 0;						
						//console.log(this.check[device_id]);
					}					
				})
				.catch(error => {
					this.orders[device_id] = orderId;
					console.log('Catch:' + error);
				});	
			}					
		},
		pay(device_id){			
			this.check[device_id] = 0;			
		}
	},
	mounted(){	
		
		this.loadWashes();
	}
}
</script>
<style scoped>
  .icon-loading{
    animation-name: rotation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;    
  }
  .offline{
    background-image: repeating-linear-gradient(-60deg, #555 0, #555 1px, transparent 1px, transparent 5px) !important;
  }
  .busy{
    animation: busy 0.6s infinite alternate;
  }

@keyframes rotation {
  0% {
      transform:rotate(0deg);
  }
  100% {
      transform:rotate(360deg);
  }
}
@keyframes busy {
    from {opacity: 1;}
    to {opacity: 0.5;}
}
</style>
