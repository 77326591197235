<template>
    <v-main>      
        <v-subheader>Скидка на ближайшую мойку:</v-subheader>
        <v-container  class="pt-0">                        
            <v-card v-if="active != null"
                class="refbg mt-0"
                dark
            >
                <v-card-title>
                    {{active.DISCOUNT_NAME}}
                </v-card-title>
                <v-card-subtitle class="pb-0">
                    {{active.COUPON}}<br/>
                    Действует {{active.ACTIVE_TO}} {{active.MAX_USE == 0?'на один заказ':'на '+active.MAX_USE +' '+declOfNum(active.MAX_USE, ['заказ', 'заказа', 'заказов'])+' (осталось '+(active.MAX_USE - active.USE_COUNT)+')'}}
                </v-card-subtitle>
                <v-card-actions>
                    <v-btn @click="clearCode()" :loading="clearloading" depressed color="error" small>Деактивировать</v-btn>
                </v-card-actions>
            </v-card>            
            <v-alert 
                v-else
                type="info" 
                class="ma-0 body-2" 
                dense
                outlined
                prominent
                border="left" 	
            >
                Ни одного купона не активировано
            </v-alert>                
        </v-container>
          
        <v-skeleton-loader 
            :loading="loading"
            type="list-item-two-line"
            transition="fade-transition"
        >
            <v-list v-show="coupons.length" class="pa-0">
                <v-subheader>Доступные купоны для активации</v-subheader>
                <v-divider></v-divider>
                <v-list-item-group v-model="selectcoupon" color="primary">
                <template v-for="(coupon, i) in coupons">
                    <v-list-item                        
                        :key="i"
                    >
                        <v-list-item-content>
                            <v-list-item-title>{{coupon.DISCOUNT_NAME}}</v-list-item-title>
                            <v-list-item-subtitle><span class='text--primary'>{{coupon.COUPON}}</span></v-list-item-subtitle>
                            <v-list-item-subtitle>{{'Действует '+coupon.ACTIVE_TO}}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{coupon.MAX_USE == 0?'на один заказ': 'на '+coupon.MAX_USE +' '+declOfNum(coupon.MAX_USE, ['заказ', 'заказа', 'заказов'])+' (осталось '+(coupon.MAX_USE - coupon.USE_COUNT)+')'}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-scroll-x-reverse-transition>
                                <v-btn v-show="i == selectcoupon"
                                    @click.stop="applyCode(coupon)" 
                                    :loading="applyloading==coupon.ID"                                    
                                    class="primary"
                                    depressed
                                    small                                    
                                >
                                    Активировать
                                </v-btn>

                            </v-scroll-x-reverse-transition>                            
                        <!--
                            <v-btn v-show="i == selectcoupon" icon @click="applyCode(coupon)" :loading="applyloading==coupon.ID">
                                <v-icon color="primary">mdi-checkbox-marked-circle</v-icon>
                            </v-btn>                        
                        -->
                        </v-list-item-action>
                    </v-list-item>		
                    <v-divider
                        :key="'dev'+i"
                    ></v-divider>
                </template>
                </v-list-item-group>
            </v-list>                            
        </v-skeleton-loader>        
        <v-footer
            app
            color="white"
            elevation=4			
        >
            <v-btn
                class="close"
                icon
                v-show="showadd"
                @click="closeCode()"
            >
                <v-icon>{{'mdi-close'}}</v-icon>
            </v-btn>         
            <v-expand-transition>
            <v-container v-if="showadd">               
                <v-form>
                    <v-text-field
                        v-model="coupontoadd"
                        label="Введите код купона"
                        prepend-icon="mdi-barcode-scan"
                        placeholder="XX-XXXXX-XXXXXXX"
                        required
                        :rules="[
                            () => !!coupontoadd || 'Код не введен',                            
                        ]"                        
                    ></v-text-field>
                </v-form>
            </v-container>                            
            </v-expand-transition>
            <v-btn color="primary"				
                depressed
                block
                :loading="addloading"
                @click="sendCode()"
            >
            Добавить купон
            </v-btn>			
        </v-footer>
    </v-main>
</template>
<script>
import axios from 'axios';
export default {
	name: "Discounts", 	
    props:['props'],
	data() {
        return {
            coupontoadd:'',
            selectcoupon:-1,
            loading:true,
            addloading:false,
            applyloading:0,
            clearloading:false,
            coupons:[],
            active:null,
            showadd:false,
        }
    },
	methods:{
		getCoupons(){ 
            this.selectcoupon = -1;
			let params = new URLSearchParams();
			params.append('sessid', this.props.user.sessid);
			params.append('phone', this.props.user.phone);
			params.append('token', this.props.user.token);
			axios({
				method: 'post',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                url: this.props.apiUrl+'getcoupons.json',
				//url: 'https://mousam.ru/test/coupon.php?method=getcoupons',
				data:params,
				withCredentials: true
			})				
			.then(response => {
                this.loading = false;
                console.log(response.data);
                if(response.data.error != null){
                    console.log(response.data.error);
                }else{
                    this.coupons = response.data.result.items;
                    this.active = response.data.result.active;
                }               
			})
			.catch(error => {                
				console.log(error);
			});
        },
        applyCode(coupon){
            this.applyloading = coupon.ID;            
            let params = new URLSearchParams();
            params.append('sessid', this.props.user.sessid);
            params.append('phone', this.props.user.phone);
            params.append('token', this.props.user.token);
            params.append('coupon', coupon.COUPON);            
            axios({
                method: 'post',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                url: this.props.apiUrl+'applycoupon.json',
                //url: 'https://mousam.ru/test/coupon.php?method=applycoupon',
                data:params,
                withCredentials: true
            })				
            .then(response => {
                console.log(response.data);                
                /*&if(response.data.error != null){
                    this.$emit('show-dialog', {title:'Ошибка', message:'Купон недействителен'});
                }else{
                    this.$emit('show-dialog', {title:'Успешно', message:'Промокод был успешно активирован'});
                }
                this.addloading = false;
                this.coupon = '';*/
                if(response.data.error != null){
                    this.$emit('show-dialog', {title:'Ошибка', message:'Купон недействителен'});
                }else{
                    if(response.data){
                        this.props.user.coupon = coupon.COUPON;
                        this.$emit('event', {name:'saveUser'});
                    }
                }
                this.applyloading = 0;
                this.getCoupons();
            })
            .catch(error => {
                this.applyloading = 0;
                console.log(error);
            });
        },
        clearCode(){
            this.clearloading = true;            
            let params = new URLSearchParams();
            params.append('sessid', this.props.user.sessid);
            params.append('phone', this.props.user.phone);
            params.append('token', this.props.user.token);            
            axios({
                method: 'post',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                url: this.props.apiUrl+'clearcoupon.json',
                //url: 'https://mousam.ru/test/coupon.php?method=clearcoupon',
                data:params,
                withCredentials: true
            })				
            .then(response => {
                console.log(response.data);                
                this.clearloading = false;
                this.getCoupons();
                this.props.user.coupon = '';
                this.$emit('event', {name:'saveUser'});
            })
            .catch(error => {
                this.clearloading = false;
                console.log(error);
            });
        },          
        sendCode(){
            if(this.showadd){
                if(this.coupontoadd){
                    this.addloading = true;
                    let params = new URLSearchParams();
                    params.append('sessid', this.props.user.sessid);
                    params.append('phone', this.props.user.phone);
                    params.append('token', this.props.user.token);
                    params.append('coupon', this.coupontoadd);
                    axios({
                        method: 'post',
                        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                        url: this.props.apiUrl+'addcoupon.json',
                        //url: 'https://mousam.ru/test/coupon.php?method=addcoupon',
                        data:params,
                        withCredentials: true
                    })				
                    .then(response => {
                        console.log(response.data);
                        if(response.data.error != null){
                            this.$emit('show-dialog', {title:'Ошибка', message:response.data.error.message});
                        }else{
                            this.$emit('show-dialog', {title:'Успешно', message:'Купон был успешно активирован'});
                        }
                        this.addloading = false;
                        this.coupontoadd = '';
                        this.getCoupons();
                    })
                    .catch(error => {
                        this.addloading = false;
                        console.log(error);
                    });
                }                
            }else{
                this.showadd = true;
            }
        },
        closeCode(){
            this.showadd = false;
        },
        declOfNum(n, titles) {
            return titles[n%10==1 && n%100!=11 ? 0 : n%10>=2 && n%10<=4 && (n%100<10 || n%100>=20) ? 1 : 2];
        }

    },    
	mounted(){			
		this.getCoupons();
	}     
}
</script>
<style scoped>
  .refbg{
    background: linear-gradient(120deg, #F78154, #F2C14E);    
  }
  .close{
    position:absolute;
    right:5px;
    top:5px;
  }  
</style>