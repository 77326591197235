<template>
	<v-main>
		<div id="сhatra" style="position:fixed;top:48px;left:0;right:0;bottom:0"></div>
	</v-main>
</template>
<script>
export default {
	name: "Support",    
	props:['props'],	
	data() {
		return {
			src:''
		}
	},
	async mounted(){
		window.ChatraID = 'Hd6CqBMkMkLaqjpro'
		let chatra = document.createElement('script');
		chatra.setAttribute('src', 'https://call.chatra.io/chatra.js');
		chatra.setAttribute('async', true);		
		document.head.appendChild(chatra);

		window.ChatraSetup = {
			mode: 'frame',
			injectTo: 'сhatra' /* id блока, в который будет встроен чат */
		};
		window.ChatraIntegration = {		
			phone: this.props.user.phone,
			notes: 'ID:'+this.props.user.id+' \n'+'Долг:'+this.props.user.credit+' \n'+'Карт:'+this.props.user.cards,
		};
	}	
}
</script>