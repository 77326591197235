import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
Vue.config.productionTip = false;

document.addEventListener(  
  typeof cordova !== 'undefined' ? 'deviceready' : 'DOMContentLoaded',
  (event) => {    
    console.log(event);
    new Vue({
      vuetify,  
      server:window.server_data, // только для онлайна
      firebase:window.FirebasePlugin, // только для кордовы
      device:window.device, // только для кордовы  
      cordova:window.cordova, // только для кордовы  
      sharing: typeof window.plugins == 'object' ? window.plugins.socialsharing:false, // только для кордовы  
      render: h => h(App)
    }).$mount('#app');    
  }
);
