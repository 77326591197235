<template>  
  <v-main>
    <v-progress-circular
      v-if="washes.length==0"
      indeterminate
      color="primary"
      size="64"
      class="loader"
    ></v-progress-circular>
    <div id="map">
    </div>
    <v-slide-y-reverse-transition>
    <v-card
      v-if="ratingOrderId"
      class="bottom-card"      
    >    
      <v-card-title class="text-center pb-0">Оцените обслуживание</v-card-title>
      <div class="text-center">
        <v-rating 
          v-model="rating"
          background-color="yellow accent-3"
          color="yellow accent-3"          
        ></v-rating>
      </div>
      
      <v-card-text>    
        <v-sheet           
          class="refbg pa-2 px-3 rounded-lg"
          dark
          @click="$emit('show-page','Referal')"   
        >
          <div class="subtitle-1" color="black">Пригласить друзей</div>
          100 рублей за каждого друга.
        </v-sheet>     
        <v-alert           
          :type="status" 
          class="body-2 ma-0 mt-4" 
          dense
          outlined
          prominent
          border="left"          
        >
          <div class="title">{{sum}} руб.</div>
          {{statusMessage}}
        </v-alert>
      </v-card-text>
      <v-card-actions>  
        <v-btn depressed block color="primary" @click="closeRating()">Закрыть</v-btn>
      </v-card-actions>      
    </v-card>
    </v-slide-y-reverse-transition>
    <v-slide-y-reverse-transition>
    <v-card v-if="currentWash && !ratingOrderId"
      class="bottom-card"      
    >
      <v-btn
        class="close"
        icon
        @click="closeWash()"
      >
        <v-icon>{{'mdi-close'}}</v-icon>
      </v-btn>
      <v-card-title>{{washes[currentWash].NAME}}</v-card-title>
      <v-card-subtitle>      
        <v-row no-gutters>
          <v-col cols="10">
            <v-icon small color="blue darken-2">{{'mdi-map-marker'}}</v-icon> {{washes[currentWash].ADDRESS}}<br/>
            <v-icon small color="green darken-2">{{'mdi-av-timer'}}</v-icon> Работает {{washes[currentWash].TIME_WORK}}
          </v-col>
          <v-col cols="2">
            <v-btn
              v-if="washes[currentWash].PREVIEW_PICTURE"
              class="float-right"
              color="secondary"
              fab
              x-small
              dark
              depressed
              outlined
              @click="showWashInfo()"
            >
              <v-icon>mdi-camera</v-icon>
            </v-btn>              
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text
        class="pb-0"
      >       
        <v-chip-group
          v-model="currentDevice"          
          :mandatory="!!currentDevice"
          active-class="primary white--text"
        >
          <v-chip v-for="(box, index) in washes[currentWash].BOXES" 
            :key="index" 
            :value="box.XML_ID" 
            :disabled="box.ONLINE != 1 || (box.XML_ID != currentDevice && !!orderId)"
            :dark="box.XML_ID == currentDevice"
            :class="{
              'busy': box.XML_ID == currentDevice && box.ONLINE == 2,
              'offline': box.ONLINE == 0,
            }"            
            :color="box.ONLINE == 2 ? 'primary':''"                        
            label                        
          >
            <v-icon v-if="box.ONLINE==null" class="icon-loading">mdi-loading</v-icon>
            <v-icon v-else-if="box.ONLINE==0">mdi-flash-off</v-icon>            
            <v-icon v-else-if="box.ONLINE==2">mdi-car-wash</v-icon>            
            <v-icon v-else>mdi-numeric-{{box.SORT}}-circle-outline</v-icon>            
          </v-chip>
        </v-chip-group>      
        <v-chip        
          v-if="!currentDevice"
          color="red"
          label
          small
          outlined          
        >
          <v-icon left>mdi-chevron-up</v-icon>
          Выберите бокс
          <v-icon right>mdi-chevron-up</v-icon>
        </v-chip>       
        <template v-else-if="deviceIndex && !washes[currentWash].BOXES[deviceIndex].PACKS">
          <v-chip
            v-if="props.user.info.ticket.id"
            label
            small                           
            color="orange darken-2"  
            dark  
          >
            <v-icon small left>mdi-ticket</v-icon> По абонементу ({{props.user.info.ticket.count}})
          </v-chip>
          <template v-else>
            <v-chip                     
              label          
              small                 
              dark
              color="green"
              text-color="black"
              class="mr-1"
            >
              {{devicePrice}} <v-icon small>mdi-currency-rub</v-icon>/мин
            </v-chip> 
            <v-chip                   
              v-if="props.user.coupon"
              left
              label
              small                               
              color="orange darken-2"  
              dark  
              class="mr-1"
            >          
              <v-icon left>mdi-barcode-scan</v-icon>          
              Купон
            </v-chip>
            <v-chip  
              v-else                  
              label
              small                 
              color="red"  
              outlined  
              class="mr-1"
            >
              от {{devicePrice*5}} <v-icon small>mdi-currency-rub</v-icon>
            </v-chip>      
          </template>                                 
        </template> 
        <v-chip  
          v-if="deviceIndex && washes[currentWash].BOXES[deviceIndex].HOOVER"
          label
          small                 
          color="yellow accent-3"  
          text-color="black"          
          class="mr-1"
        >
          Есть пылесос
        </v-chip>        
        <v-chip 
          v-if="currentDevice && washes[currentWash].BOXES[deviceIndex].PACKS"
          color="red"
          label
          small
          outlined
        >
          <v-icon left>mdi-chevron-down</v-icon>
          Выберите вариант
          <v-icon right>mdi-chevron-down</v-icon>
        </v-chip>       
        <v-item-group 
          class="mt-2"
          v-if="deviceIndex && washes[currentWash].BOXES[deviceIndex].PACKS"
          v-model="currentPack"
          :mandatory="!!currentPack"
          transition="scroll-y-transition"
          active-class="success"
        >
          <v-row
            dense
          >
            <v-col
              v-for="(pack, index) in washes[currentWash].BOXES[deviceIndex].PACKS"              
              :key="'pack'+index"              
              cols="4"              
            >          
              <v-item v-slot="{active, toggle}"
                :value="pack.id" 
              >
                <v-card
                  outlined       
                  :dark="active"                  
                  @click="toggle"
                >
                  <v-card-subtitle class="px-2 py-1" :class="active?'white--text':''">
                    {{pack.name}}
                  </v-card-subtitle>
                  <v-card-text class="px-2 pb-1" :class="active?'white--text':''">
                    <b>{{pack.price}} {{pack.qurrency}}</b>
                  </v-card-text>
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>                           
      </v-card-text>
      <v-card-actions>  
        <v-btn 
          v-if="orderId == 0 || orderId == -1" @click="startDevice()" 
          depressed 
          block 
          color="primary"           
          :disabled="currentDevice == '' || (washes[currentWash].BOXES[deviceIndex].PACKS && currentPack == '')" 
          :loading="orderId == -1"
        >
          <span v-if="deviceIndex && washes[currentWash].BOXES[deviceIndex].CASHCODE">Пополнить</span>
          <span v-else>Включить</span>
        </v-btn>
        <!-- Кнопки если есть пылесос-->
				<v-row dense v-else-if="washes[currentWash].BOXES[deviceIndex].HOOVER">
          <v-col cols="6">
            <v-btn depressed block color="yellow accent-3" v-if="currentOption == 'wash' " @click="startHoover()">Пылесос</v-btn>
            <v-btn depressed block color="primary" v-else @click="stopHoover()">Мойка</v-btn>
          </v-col>          
					<v-col cols="6">
            <v-btn depressed block color="secondary" @click="stopDevice()">Выключить</v-btn>
					</v-col>          
				</v-row>
        <!-- Кнопки елси есть пауза-->
        <!--
        <v-row dense v-else-if="!currentPack">
          <v-col cols="6">
            <v-btn depressed block color="secondary" @click="stopDevice()">Выключить</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn depressed block color="secondary" @click="stopDevice()">Выключить</v-btn>
          </v-col>
        </v-row>-->
        <v-btn v-else-if="!currentPack" depressed block color="secondary" @click="stopDevice()">Выключить</v-btn>          
        <v-btn v-else
          :loading="deviceTimer<=0"          
          depressed 
          block 
          dark 
          color="black">
            Аппарат <span v-if="deviceIndex && washes[currentWash].BOXES[deviceIndex].CASHCODE">пополняется</span><span v-else>включен</span> {{deviceDigits}}
          </v-btn>
      </v-card-actions>
    </v-card>
    </v-slide-y-reverse-transition>    
		<v-dialog v-model="showInfo" fullscreen>
      <v-card
        v-if="currentWash"
        tile>
        <v-toolbar dark dense color="primary">
          <v-toolbar-title>Информация о мойке</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="showInfo = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>												
          </v-toolbar-items>
        </v-toolbar>
        <v-card-title>{{washes[currentWash].NAME}}</v-card-title>        
        <v-card-text>          
          <v-img :src="washes[currentWash].PREVIEW_PICTURE">
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-row>
          </template>          
          </v-img>
        </v-card-text>      
        <v-card-subtitle>
            <v-icon small color="blue darken-2">{{'mdi-map-marker'}}</v-icon> {{washes[currentWash].ADDRESS}}<br/>
            <v-icon small color="green darken-2">{{'mdi-av-timer'}}</v-icon> Работает {{washes[currentWash].TIME_WORK}}        
        </v-card-subtitle>
      </v-card>
		</v-dialog>		    
  </v-main>
</template>

<script>
import GoogleMapsApiLoader from 'google-maps-api-loader';
import { loadYmap } from 'vue-yandex-maps';
import axios from 'axios';
  export default {
    name: "Home",
    props:['props'],
    data() {
      return {        
        //googlemap
        googlemap: null,
        map: {},
        goodleMapOptions: {
          center: { lat: 56.038843141791, lng: 92.936760357335 },
          // center: { lat: 55.985964931523, lng: 92.930914933202 },
          zoom: 11,
          disableDefaultUI: true,
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          gestureHandling: 'greedy'
        },        
        directionsService:null,
        directionsRenderer:null,
        //end googlemap        
        //yandexmap
        yandexmap: null,
        yandexMapOptions: {
          center: [56.038843141791, 92.936760357335 ],
          zoom: 11,
          route:null
        },
        //endyandexmap
        userPosition:{
          lat:0,
          lng:0,
          az:0,
          enable:false                    
        },        
        washes: [],
        currentWash: '',
        currentDevice: '',
        currentPack:'',
        currentOption:'wash',
        deviceTimer:'',
        deviceCounter:0,
        orderId: 0,        
        ratingOrderId:0,
        rating:	0,
        sum:0,
        status:null,
        statusMessage:'',
        showInfo:false
      };
    },
    computed:{
      devicePrice(){
        let price = null;
        for(let i in this.washes[this.currentWash].BOXES){
          if(this.washes[this.currentWash].BOXES[i].XML_ID == this.currentDevice){
            price = this.washes[this.currentWash].BOXES[i].PRICE;
            break;
          }
        }     
        return price;
      },
      deviceIndex(){
        let index = null;
        for(let i in this.washes[this.currentWash].BOXES){
          if(this.washes[this.currentWash].BOXES[i].XML_ID == this.currentDevice){
            index = i;
            break;
          }
        }     
        return index;
      },
      deviceDigits(){
        let minutes = Math.floor(this.deviceTimer/60);
        let seconds = this.deviceTimer%60;
        return this.zeroPadding(minutes, 2) + ':' + this.zeroPadding(seconds, 2);
      }            
    },
    watch:{
      rating: function () {
        this.rateOrder();
      }
    },
    methods:{
      zeroPadding(num, digit) {
        var zero = '';
        for(var i = 0; i < digit; i++) {
          zero += '0';
        }
        return (zero + num).slice(-digit);
      },
      initOrderTimer(seconds){
        this.deviceTimer = seconds;      
        this.deviceCounter = setInterval(() => {
          this.deviceTimer--;
          if(this.deviceTimer<=0){
            clearInterval(this.deviceCounter);
            this.washes[this.currentWash].BOXES[this.deviceIndex].ONLINE = 1;
            this.loadOrderInfo();
          }
        }, 1000);
      },
      loadWashes(){
        axios
        .get(this.props.apiUrl+'getdevices.json', {
						phone: this.phone						
				})      
        .then(response => {
          if(response.data.result){            
            this.washes = response.data.result;

            let Lng = [];         
            let Lat = [];

            //Растановка маркеров на googlemap
            
            /*let image = {
              url:require('@/assets/logo.svg'),
              size: new this.googlemap.maps.Size(50, 50),
              scaledSize: new this.googlemap.maps.Size(50, 50)
            };

            for(let washCode in this.washes){              
              let marker = new this.googlemap.maps.Marker({
                position: {lat: this.washes[washCode].lat*1, lng: this.washes[washCode].lon*1},
                id: washCode,
                icon: image,
                map: this.map
              });
              let _this = this;
              marker.addListener('click', function(){
                _this.openWash(this.id);
              });
              //marker.setMap(this.map);
              this.washes[washCode].marker = marker;              
              Lat.push(this.washes[washCode].lat*1);
              Lng.push(this.washes[washCode].lon*1);
            }*/

            //Растановка маркеров на yandexmap
            for(let washCode in this.washes){     	
              let marker = new this.yandexmap.Placemark(
                  [this.washes[washCode].lat*1, this.washes[washCode].lon*1], 
                  {
                    iconCaption:washCode
                  }, 
                  {
                    iconLayout: 'default#image',
                    iconImageHref: require('@/assets/logo.svg'),
                    iconImageSize: [50, 50],
                    iconImageOffset: [-25, -50]
                  }
              );                                      
              this.map.geoObjects.add(marker);
              this.washes[washCode].marker = marker;

              //let _this = this;
              marker.events.add('click', (e) => {
                  
                  this.openWash(e.get('target').properties.get('iconCaption'));
                  //console.log(e.get('target').properties.get('iconCaption'));
                  //console.log(e.get('target').geometry.getCoordinates());
                  //console.log(this.userPosition);
              });

              

              /*
              marker.addListener('click', function(){
                _this.openWash(this.id);
              });*/
              //this.washes[washCode].marker = marker;              
              Lat.push(this.washes[washCode].lat*1);
              Lng.push(this.washes[washCode].lon*1);
            }

            this.loadInfo();
            if(this.currentWash){              
              this.clearDevicesStatus(this.currentWash);
              this.pingDevices(this.currentWash);                            
            }
            this.loadOrderInfo();            
            //Определение гео позиции пользователя
            this.initUserPosition({lat:Lat, lng:Lng});
          }/*else{
            console.log(response.data);
          }  */        
        })
        .catch(error => {
          console.log(error);
        });
        
      },
      openWash(washCode){
        if(this.orderId == 0){          
          this.currentWash = washCode;						
          this.currentDevice = '';
          this.currentPack = '';
          this.currentOption = 'wash';
          this.saveInfo();	
          //this.googleNavigateToWash(this.currentWash);
          this.yandexNavigateToWash(this.currentWash);
          this.clearDevicesStatus(this.currentWash);
          this.pingDevices(this.currentWash);
        }else{
          this.$emit('show-dialog', {title:'Внимание', message:'В данный момент для Вас включена мойка. Прежде чем закрыть окно с мойкой, надо выключить аппарат.'});
        }
      },
      closeWash(){		
        if(this.orderId ==  0){
          //google
          //this.directionsRenderer  = new this.googlemap.maps.DirectionsRenderer();          
          //this.directionsRenderer.setMap(null);
          //this.map.setOptions(this.goodleMapOptions);

          //yandex          
          this.map.geoObjects.remove(this.yandexMapOptions.route);
          this.map.setCenter(this.yandexMapOptions.center, this.yandexMapOptions.zoom);

          this.clearDevicesStatus(this.currentWash);
          this.currentWash = '';
          this.currentDevice = '';	
          this.currentPack = '';
          this.currentOption = 'wash';
          this.saveInfo();
        }else{
          this.$emit('show-dialog', {title:'Внимание', message:'В данный момент для Вас включена мойка. Прежде чем закрыть окно с мойкой, надо выключить аппарат.'});
        }
      },
      showWashInfo(){
        this.showInfo = true;
      },
      clearDevicesStatus(washCode){
        for(let i in this.washes[washCode].BOXES){
          this.washes[washCode].BOXES[i].ONLINE = null;
        }	
      },
      pingDevices(washCode){        
        for(let i in this.washes[washCode].BOXES){             
          axios
            .get(this.props.apiUrl+'ping.json?id='+this.washes[washCode].BOXES[i].XML_ID, {timeout: 5000})
            .then(response => {
              this.washes[washCode].BOXES[i].ONLINE = response.data.result;
              if(response.data.result == 2 && this.washes[washCode].BOXES[i].XML_ID == this.currentWash && this.orderid == 0){
                this.currentWash = '';
                this.currentPack = '';
              }
              //console.log(this.washes[washCode].BOXES[i].XML_ID+':   '+response.data.result);
            })
            .catch(error => {
              this.washes[washCode].BOXES[i].ONLINE = 0;
              console.log('false:' + error);
            });
          if(this.washes[washCode].BOXES[i].XML_ID == this.currentDevice && this.washes[washCode].BOXES[i].ONLINE == 0){
            this.currentDevice = '';
          }          
          //console.log(this.washes[washCode].BOXES[i].XML_ID+': '+this.washes[washCode].BOXES[i].ONLINE);
        }        
      },
      loadOrderInfo(){
        if(this.orderId){
          let params = new URLSearchParams();          
          params.append('id', this.orderId);
          params.append('sessid', this.props.user.sessid);
          params.append('phone', this.props.user.phone);
          params.append('token', this.props.user.token);
          axios({
            method: 'post',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: this.props.apiUrl+'getorder.json',
            data:params,
            withCredentials: true
          })			
          .then(response => {
            if(response.data.error != null){
              this.orderId = 0;
              this.currentPack = '';
              //console.log(response.data);            
            }
            else{
              if(response.data.result.closed){
                this.openRating(this.orderId, response.data.result.payment);
                this.orderId = 0;   
                this.currentPack = '';             
                //this.$emit('show-dialog', {title:'Автоотключение', message: 'Аппарат был выключен автоматически. Стоимость мойки составила '+response.data.result.price+ ' руб.'});                
              }else{
                if(this.currentPack){
                  this.initOrderTimer(this.washes[this.currentWash].BOXES[this.deviceIndex].PACKS[this.currentPack].min*60 - response.data.result.elapsedtime);
                }
                console.log(response.data.result);
              }
            }
            this.saveInfo();
          })
          .catch(error => {
            console.log('Catch:' + error);
          });          
        }
      },
      startDevice(){		
        console.log(this.washes[this.currentWash].BOXES[this.deviceIndex].PACKS && this.currentPack == '');	
        console.log(this.washes[this.currentWash].BOXES[this.deviceIndex].PACKS);	
        console.log(this.currentPack);	
        if(!this.props.user.id){
          this.$emit('show-dialog', 
            {
              title:'Вы не авторизованы', 
              message: 'Чтобы воспользоваться сервисом, необходимо авторизоваться. Авторизоваться сейчас?', 
              type:'confirm', 
              callback:()=>{
                this.$emit('show-page', 'Auth');
              }
            }
          );
          return;
        }
        if(!this.props.user.cards){
          this.$emit('show-dialog', 
            {
              title:'Нет карты', 
              message: 'Чтобы воспользоваться сервисом, необходимо привязать пластиковую карту. Привязать карту сейчас?', 
              type:'confirm', 
              callback:()=>{                
                this.$emit('show-page', 'Cards');
              }
            }
          );
          return;
        }
        if(this.props.user.credit){
          this.$emit('show-dialog', 
            {
              title:'Имеется долг', 
              message: 'У вас есть непогашенная задолженность в размере '+this.props.user.credit+' руб. Погасить задолженность вы можете в итории заказов. Перейти в историю заказов?', 
              type:'confirm', 
              callback:()=>{
                this.$emit('show-page', 'Orders');                
              }
            }
          );
          return;
        }
        if(this.orderId == 0){
          this.orderId = -1;
          //console.log('Включаем '+this.currentDevice);	
          let params = new URLSearchParams();
          params.append('deviceid', this.currentDevice);
          params.append('sessid', this.props.user.sessid);
          params.append('phone', this.props.user.phone);
          params.append('token', this.props.user.token);
          if(this.currentPack && this.washes[this.currentWash].BOXES[this.deviceIndex].PACKS){
            params.append('packid', this.currentPack);
          }
          axios({
            method: 'post',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: this.props.apiUrl+'startdevice.json',
            data:params,
            withCredentials: true
          })			
          .then(response => {
            if(response.data.error != null){
              this.orderId = 0;
              this.$emit('show-dialog', {title:'Ошибка', message: response.data.error.message});
              this.pingDevices(this.currentWash);
              //console.log(response.data);              
            }
            else{
              this.orderId = response.data.result.orderid;
              this.washes[this.currentWash].BOXES[this.deviceIndex].ONLINE = 2;
              if(this.currentPack){     
                if(this.washes[this.currentWash].BOXES[this.deviceIndex].CASHCODE){
                  this.initOrderTimer(10);
                }else{
                  this.initOrderTimer(this.washes[this.currentWash].BOXES[this.deviceIndex].PACKS[this.currentPack].min*60);
                }
              }
            }
            this.saveInfo();
          })
          .catch(error => {
            this.orderId == 0;
            console.log('Catch:' + error);
          });                            
          //this.pingDevices(this.currentWash); 
        }
      },
      stopDevice(){
        if(this.orderId > 0){
          //console.log('Выключаем');
          let orderId = this.orderId;
          this.orderId = -1;
          let params = new URLSearchParams();
          params.append('deviceid', this.currentDevice);
          params.append('orderid', orderId);
          params.append('sessid', this.props.user.sessid);
          params.append('phone', this.props.user.phone);
          params.append('token', this.props.user.token);          
          axios({
            method: 'post',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: this.props.apiUrl+'stopdevice.json',
            data:params,
            withCredentials: true
          })			
          .then(response => {
            if(response.data.error != null){
              this.orderId = orderId;
              if(response.data.error.code == -214){
                this.orderId = 0;
              }              
              this.$emit('show-dialog', {title:'Ошибка', message: response.data.error.message});  
              //console.log(response.data);            
            }
            else{
                this.openRating(orderId, response.data.result.payment);
                this.orderId = 0;
                this.currentOption = 'wash';
                this.props.user.info.ticket.count--;
                this.$emit('event', {name:'authByToken'});                
                /*if(response.data.result.payment.error != null){
                  this.$emit('show-dialog', {title:'Ошибка', message: response.data.result.payment.error.message});
                }else{
                  this.$emit('show-dialog', {title:'Успешно', message: response.data.result.payment.result.message+': списано '+response.data.result.payment.result.sum+' руб.'});
                } */                                                                               
            }
            this.saveInfo();
          })
          .catch(error => {
            this.orderId = orderId;
            this.$emit('show-dialog', {title:'Ошибка', message:'Не удалось обработать запрос и аппарат не выключился. Возможно, есть перебоии со связью. Попробуйте еще раз'});
            console.log('Catch:' + error);
          });
        }
      },
      startHoover(){
        if(this.orderId > 0){          
          let orderId = this.orderId;
          //this.orderId = -1;
          let params = new URLSearchParams();
          params.append('deviceid', this.currentDevice);
          params.append('orderid', orderId);
          params.append('sessid', this.props.user.sessid);
          params.append('phone', this.props.user.phone);
          params.append('token', this.props.user.token);          
          axios({
            method: 'post',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: this.props.apiUrl+'starthoover.json',
            data:params,
            withCredentials: true
          })			
          .then(response => {
            if(response.data.error != null){
              //this.orderId = orderId;              
              this.$emit('show-dialog', {title:'Ошибка', message: response.data.error.message});  
            }
            else{
              this.currentOption = 'hoover';
            }
            this.saveInfo();
          })
          .catch(error => {
            //this.orderId = orderId;
            this.$emit('show-dialog', {title:'Ошибка', message:'Не удалось обработать запрос. Возможно, есть перебоии со связью. Попробуйте еще раз'});
            console.log('Catch:' + error);
          });
        }
      },      
      stopHoover(){
        if(this.orderId > 0){          
          let orderId = this.orderId;
          //this.orderId = -1;
          let params = new URLSearchParams();
          params.append('deviceid', this.currentDevice);
          params.append('orderid', orderId);
          params.append('sessid', this.props.user.sessid);
          params.append('phone', this.props.user.phone);
          params.append('token', this.props.user.token);          
          axios({
            method: 'post',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: this.props.apiUrl+'stophoover.json',
            data:params,
            withCredentials: true
          })			
          .then(response => {
            if(response.data.error != null){
              //this.orderId = orderId;              
              this.$emit('show-dialog', {title:'Ошибка', message: response.data.error.message});                
            }
            else{
              this.currentOption = 'wash';              
            }
            this.saveInfo();
          })
          .catch(error => {
            //this.orderId = orderId;
            this.$emit('show-dialog', {title:'Ошибка', message:'Не удалось обработать запрос. Возможно, есть перебоии со связью. Попробуйте еще раз'});
            console.log('Catch:' + error);
          });
        }
      },      
      rateOrder(){
        if(this.ratingOrderId > 0){
          let params = new URLSearchParams();          
          params.append('orderid', this.ratingOrderId);
          params.append('rating', this.rating);
          params.append('sessid', this.props.user.sessid);
          params.append('phone', this.props.user.phone);
          params.append('token', this.props.user.token);  
          axios({
            method: 'post',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: this.props.apiUrl+'rateorder.json',
            data:params,
            withCredentials: true
          })
          .then(response => {
            console.log(response.data);
          })
          .catch(error => {            
            console.log('Catch:' + error);
          });
        }
      },
      openRating(orderId, payment){                
        console.log(payment);
        if(payment.error != null){
          this.status = "error";
          this.statusMessage = payment.error.message;
          this.sum = payment.error.sum;
        }else{          
          this.status = "success";
          this.statusMessage = payment.result.message;
          this.sum = payment.result.sum;
        }
        this.ratingOrderId = orderId;            
      },
      closeRating(){
        this.ratingOrderId = 0;
        this.rating = 0;
        this.sum = 0;
        this.status = null;
        this.statusMessage = '';        
        this.pingDevices(this.currentWash);
      },
      saveInfo(){
        let ob = {				
          currentWash: this.currentWash,
          currentDevice: this.currentDevice,
          currentPack: this.currentPack,
          orderId: this.orderId,
          currentOption: this.currentOption
        }
        window.localStorage.setItem("mapinfo", JSON.stringify(ob));
      },
      loadInfo(){
        let mapinfo = window.localStorage.getItem("mapinfo");
        if(mapinfo){          
          let ob = JSON.parse(mapinfo);
          this.currentWash = ob.currentWash;
          this.currentDevice = ob.currentDevice;
          this.currentPack = ob.currentPack;
          this.orderId = ob.orderId;			
          this.currentOption = ob.currentOption;
        }
      },
      yandexNavigateToWash(washCode){
        this.yandexMapOptions.zoom = this.map.getZoom()		
        this.yandexMapOptions.center = this.map.getCenter();   
        if(this.userPosition.enable){
          console.log("Яндекс маршрутизация - тут надо построить маршрут");
          
          //this.map.setCenter(this.washes[washCode].marker.geometry.getCoordinates(),16);
          if(this.yandexMapOptions.route){
            this.map.geoObjects.remove(this.yandexMapOptions.route);
          }
          this.yandexMapOptions.route = new this.yandexmap.multiRouter.MultiRoute({   
            // Точки маршрута. Точки могут быть заданы как координатами, так и адресом. 
            referencePoints: [
                [this.userPosition.lat, this.userPosition.lng],
                this.washes[washCode].marker.geometry.getCoordinates(),                  
            ]
          }, {                
            boundsAutoApply: true,
            wayPointVisible: false,
            routeActiveStrokeColor:'#1976d2'
          });
          // Добавление маршрута на карту.
          this.map.geoObjects.add(this.yandexMapOptions.route);          
        }else{
          this.map.setCenter(this.washes[washCode].marker.geometry.getCoordinates(),16);
        }
      },
      googleNavigateToWash(washCode){
        this.goodleMapOptions.zoom = this.map.getZoom()		
        this.goodleMapOptions.center = this.map.getCenter();        
        if(this.userPosition.enable){                    
          let request = {
              origin: this.userPosition.marker.getPosition(),
              destination: this.washes[washCode].marker.getPosition(),
              travelMode: this.googlemap.maps.TravelMode['DRIVING']              
          };
          let _this = this;
          this.directionsService.route(request, function(response, status) {
            if(status == 'OK') {              
              _this.directionsRenderer.setOptions({
                suppressMarkers:true
              });
              _this.directionsRenderer.setDirections(response);
              _this.directionsRenderer.setMap(_this.map);              
              //console.log(response);

              //response.routes[0]
              /*let washBounds = new this.googlemap.maps.LatLngBounds(
                {lat: Math.min.apply(null, Lat), lng: Math.min.apply(null, Lng)}, 
                {lat: Math.max.apply(null, Lat), lng: Math.max.apply(null, Lng)}
              );*/
              //

              //_this.mapinfo.center = response.routes[0].bounds.getCenter();
              _this.googlemap.maps.event.addListener(_this.map, 'idle', function () {
                _this.map.panBy(0, 90);
                _this.googlemap.maps.event.clearListeners(_this.map, 'idle');
              });
              
              //console.log(response.routes[0].legs[0].distance.text);
              //console.log(response.routes[0].legs[0].duration.text);
              //this.map.fitBounds(washBounds, 10);              
            }
          });          
        }else{
          this.map.setOptions({
            center:this.washes[washCode].marker.getPosition(),
            zoom:16						
          });			
          this.map.panBy(0, 90);
        }
      },      
      //async getUserPosition(){},
      initUserPosition(washesPos){
        console.log('initUserPosition');
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position => {
            console.log('Навигация поддерживается');
            this.userPosition.lat =position.coords.latitude;
            this.userPosition.lng =position.coords.longitude;
            this.userPosition.az =position.coords.heading;
            this.userPosition.enable = true;                
            //return {lat:this.userPosition.lat,lng:this.userPosition.lng};            

            //googlemap
            /*
            this.userPosition.marker = new this.googlemap.maps.Marker({
              position: {
                lat: this.userPosition.lat,
                lng: this.userPosition.lng
              },
              id: 'user',
              icon: require('@/assets/location.svg'),
              //zIndex: 10000,
              map: this.map
            });                    
            this.goodleMapOptions.center = this.userPosition.marker.getPosition();
            this.goodleMapOptions.zoom = 13;
            this.map.setOptions(this.goodleMapOptions);
            if(this.currentWash){
              this.googleNavigateToWash(this.currentWash);  
            } */      
            
            //yandexmap
            this.yandexMapOptions.center = [this.userPosition.lat, this.userPosition.lng];
            this.yandexMapOptions.zoom = 13;            
            this.map.setCenter(this.yandexMapOptions.center, this.yandexMapOptions.zoom);
            if(this.currentWash){
              this.yandexNavigateToWash(this.currentWash);  
            }

          }, 
          error => {
            console.log('Навигация поддерживается, но пользователь отказался');
            console.log(error);   
            
            //googlemap  
            /*
            let washBounds = new this.googlemap.maps.LatLngBounds(
              {lat: Math.min.apply(null, washesPos.lat), lng: Math.min.apply(null, washesPos.lng)}, 
              {lat: Math.max.apply(null, washesPos.lat), lng: Math.max.apply(null, washesPos.lng)}
            );              
            this.goodleMapOptions = washBounds.getCenter();
            this.map.setCenter(this.goodleMapOptions);
            this.map.fitBounds(washBounds, 10);
            if(this.currentWash){
              this.googleNavigateToWash(this.currentWash);  
            } 
            */        

            //yandexmap          
            
            this.map.setBounds([[Math.max.apply(null, washesPos.lat), Math.min.apply(null, washesPos.lng)], [Math.min.apply(null, washesPos.lat),Math.max.apply(null, washesPos.lng)]]); 
            this.yandexMapOptions.center = this.map.getCenter();
            if(this.currentWash){
              this.yandexNavigateToWash(this.currentWash);  
            }

          });
        }
        else{
          console.log('Навигация не поддерживается');
          //googlemap
          /*let washBounds = new this.googlemap.maps.LatLngBounds(
            {lat: Math.min.apply(null, washesPos.lat), lng: Math.min.apply(null, washesPos.lng)}, 
            {lat: Math.max.apply(null, washesPos.lat), lng: Math.max.apply(null, washesPos.lng)}
          );                                  
          this.goodleMapOptions = washBounds.getCenter();
          this.map.setCenter(this.goodleMapOptions);
          this.map.fitBounds(washBounds, 10);
          if(this.currentWash){
            this.googleNavigateToWash(this.currentWash);  
          }*/

          //yandexmap          
          this.map.setBounds([[Math.max.apply(null, washesPos.lat), Math.min.apply(null, washesPos.lng)], [Math.min.apply(null, washesPos.lat),Math.max.apply(null, washesPos.lng)]]); 
          this.yandexMapOptions.center = this.map.getCenter();
          if(this.currentWash){
            this.yandexNavigateToWash(this.currentWash);  
          }          

        }              
      }
    },
    async mounted(){
      this.googlemap = await GoogleMapsApiLoader({
        //apiKey: 'AIzaSyC4qDTD8UzyVfQYrxW_dLptMcE2V_Q37n4'
        apiKey: 'AIzaSyC9CehZc27MKGW0ZsEvs84hy6TBEX1ITsM'        
      });
      /*this.map = new this.googlemap.maps.Map(document.getElementById('map'), this.goodleMapOptions);
      this.directionsService  = new this.googlemap.maps.DirectionsService();
      this.directionsRenderer  = new this.googlemap.maps.DirectionsRenderer();            */
      await loadYmap(
        {
          apiKey: '78894455-a569-40ff-adc0-0cd6b224cd9f',
          lang: 'ru_RU',
          coordorder: 'latlong',
          enterprise: false,
          version: '2.1',
          debug:true
        }
      ).then(() => {
        console.log('yandex');        
        this.yandexmap = window.ymaps;
        
        this.map = new this.yandexmap.Map('map', {
            controls: [],
            center: this.yandexMapOptions.center,            
            zoom: this.yandexMapOptions.zoom,
            margin:[50,10,200,10]
        });        
        this.loadWashes();     
      });
      //console.log(loadYmap);
      
      
      
      //this.loadWashes();      
    }    
  }
</script>
<style scoped>
  #map {
    width: 100%;
    height: 100%;
    position:absolute;
    z-index:0;
  }
  .loader{
    position:absolute;
    left:50%;
    top:50%;
    margin-left:-32px;
    margin-top:-64px;
    z-index:100;
  }
  .bottom-card{
    position:fixed;
    bottom:5px;
    z-index:1;
    right:5px;
    left:5px;
    max-height:80%;
    overflow:hidden;
  }
  .bottom-card .v-card__title{
    display:block;
  }
  .refbg{
    background: linear-gradient(120deg, #F78154, #F2C14E);
    background: url(./../assets/sdasdqdqw34.png) 120% 0% no-repeat;    
    background-color:#76b8cc !important;
    background-size:50%;
  }
  .close{
    position:absolute;
    right:5px;
    top:5px;
  }
  .icon-loading{
    animation-name: rotation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;    
  }
  .offline{
    background-image: repeating-linear-gradient(-60deg, #555 0, #555 1px, transparent 1px, transparent 5px) !important;
  }
  .busy{
    animation: busy 0.6s infinite alternate;
  }

@keyframes rotation {
  0% {
      transform:rotate(0deg);
  }
  100% {
      transform:rotate(360deg);
  }
}
@keyframes busy {
    from {opacity: 1;}
    to {opacity: 0.5;}
}
</style>