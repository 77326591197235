<template>
    <v-main>                      
        <v-card-text>
            <div class="text-subtitle-1">
                Отправляйте ссылку друзьям, чтобы получать купоны и платить ими за мойку.
            </div>
            <v-text-field       
                class="my-4"         
                single-line
                outlined
                dense
                readonly
                v-model="refUrl"
                id="refInput"                                                
                @click="select()"
            ></v-text-field>
            <div class="text-center">
                <v-btn                     
                    color="primary"
                    depressed
                    @click="copy()"
                >
                    {{this.props.sharing?'Отправить другу':'Скопировать'}}
                </v-btn>
            </div>
            <v-sheet                
                color="grey lighten-5"
                class="mt-8 pa-2 px-3 rounded"
            >
                Купон на 100 рублей выдается, как только ваш друг, перешедший по ссылке, помоет машину.<br/><br/>
                Отслеживать и активировать выданные купоны вы сможете в разделе "Скидки". 
                <br/><br/>
                Друг, перешедший по ссылке, получает купон 100 рублей на мойку.
            </v-sheet>                                
        </v-card-text>
    </v-main>
</template>
<script>
//import axios from 'axios';
export default {
	name: "Referal", 	
    props:['props'],
	data() {
        return {
            refUrl:'https://mousam.ru/ref/'+this.props.user.id
        }
    },
	methods:{
        copy(){
            this.select();
            if(this.props.sharing){
                this.props.sharing.share('Купон на 100 руб. на мойку авто в сети МОЮ САМ', 'Купон на мойку', null, 'https://mousam.ru/ref/'+this.props.user.id);   
            }
        },
        select(){
            document.querySelector('#refInput').select();
        }
    },    
	mounted(){			

	}     
}
</script>