<template>
  <v-app id="mousam">    
    <template v-if="carusel.show" >
    <v-btn
      class="close"
      icon
      @click="hideCarusel()"
    >      
      <v-icon>mdi-close</v-icon>
    </v-btn>    
    <v-carousel          
      :show-arrows="false"
      height="100%"
      v-model="carusel.slide"      
      delimiter-icon="mdi-circle-medium"
      hide-delimiter-background
    >     	          
      <template
        v-for="slide in carusel.items"        
      >
      <v-carousel-item
        :key="'slide'+slide.id"      
      >
        <v-sheet
          :color="slide.bg"
          height="100%"
          class="pa-4"
        >
              <v-img
                height="50%"
                :src="slide.img"
              ></v-img>
              <h2 v-html="slide.title"></h2>
              <p v-html="slide.text"></p>
              <v-btn
                color="yellow accent-3"
                light                            
                depressed                       
                @click="triggerAction(slide.button.action)"
              >
                {{slide.button.text}}
              </v-btn>
        </v-sheet>  
      </v-carousel-item>
      </template>      
    </v-carousel>    
    </template>
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-list dense>
        <v-list-item v-if="user.phone" two-line>
          <v-list-item-avatar color="primary">
            <v-icon dark x-large>mdi-account</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{user.info.name?user.info.name:'Пользователь'}}</v-list-item-title>    
            <v-list-item-subtitle>+{{user.phone}}</v-list-item-subtitle>
          </v-list-item-content>          
        </v-list-item>
        <v-list-item v-else two-line>
          <v-list-item-avatar>
            <img src="./assets/logo.svg">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Сеть моек</v-list-item-title>
            <v-list-item-subtitle>самообслуживания</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>     
        <v-divider></v-divider>
        <v-list-item
          v-for="(item, page) in menu"
          :key="item.title"
          link
          @click="showPage(page)" 
          v-show="((item.needauth && user.id!=0) || !item.needauth) && !item.hidden && !(user.id!=0 && page == 'Auth')"                    
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-2 text-caption">
        Версия {{clientversion}} 
        <v-btn v-if="clientversion!=actualversion && device.platform != null"
          x-small
          depressed
          color="error"
          :href="device.platform == 'Android'?googlepage:applepage"
        >
          Обновить
        </v-btn>
        <template v-else>
          (последняя версия)
        </template>
        </div>
      </template>      
    </v-navigation-drawer>

    <v-app-bar
      app
      color="primary"
      dark
      dense      
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>{{this.title}}</v-toolbar-title>      
      <v-spacer></v-spacer>      
      <v-btn 
        icon
        v-if="notify.counter != null && user.id"        
        @click="showNotifyList()"
      >
        <v-badge
          v-if="notify.counter!=0"
          color="error"
          :content="notify.counter"
          overlap
        >
          <v-icon>mdi-bell-outline</v-icon>
        </v-badge>
        <v-icon v-else>mdi-bell-outline</v-icon>
      </v-btn>      
    </v-app-bar>    
    <component 
      v-bind:is="page" 
      :props="{apiUrl:apiUrl, device:device, user:user, carusel:carusel, sharing:sharing}" 
      @show-dialog="showDialog"
      @show-page="showPage"
      @event="eventHandler"
    ></component>    
    <v-dialog v-model="dialog.show" persistent max-width="290">
      <v-card>
        <v-card-title>{{dialog.title}}</v-card-title>
        <v-card-text v-html="dialog.message"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>         
          <template v-if="dialog.type == 'alert'"> 
            <v-btn color="primary" depressed block @click="dialog.show = false">OK</v-btn>
          </template>
          <template v-if="dialog.type == 'confirm'">    
            <v-container fluid class="pa-0">
              <v-row no-gutters>
                <v-col cols="6" class="pa-0 pr-1">
                  <v-btn color="secondary" block depressed @click="dialog.show = false">Отмена</v-btn>
                </v-col>
                <v-col cols="6" class="pa-0 pl-1">
                  <v-btn color="primary" block depressed  @click="dialog.callback();dialog.show = false">OK</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>       
		<v-dialog 
      v-model="notify.show" 
      fullscreen
      scrollable
      transition="slide-x-reverse-transition"            
    >
      <v-card tile>
        <v-toolbar 
          dark 
          dense           
          color="primary"               
        >
          <v-toolbar-title>Уведомления</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn               
            icon 
            dark 
            @click="hideNotifyList()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>												
        </v-toolbar>   
        <v-card-text
          v-scroll.self="onNotifyScroll"
          style="height: 100%;"
          class="pa-0"
        >     
        <v-skeleton-loader
          v-if="notify.items == null"
          type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"          
        >   
        </v-skeleton-loader>
        <template v-else>
          <v-container v-if="notify.items.length==0"> 
            <v-alert 
                
                type="info" 
                class="ma-0 body-2" 
                dense
                outlined
                prominent
                border="left" 	
            >
                Нет уведомлений
            </v-alert>
          </v-container>
          <v-list
            v-else
            class="py-0"                        
          >
            <template v-for="item in notify.items"> 
              <v-card 
                :class="!item.read ? 'blue lighten-5' : ''"
                @click="markNotifyRead(item)" 
                :key="'id'+item.id"
                flat
              >                           
                <v-list-item>
                  <v-list-item-avatar>
                    <v-img 
                      v-if="item.data.img"
                      :src="item.data.img">
                    </v-img>
                    <v-icon
                      v-else
                      :class="item.data.icon?item.data.icon.color:'blue'"
                      dark
                    >
                      mdi-{{item.data.icon?item.data.icon.mdi:'information-variant'}}
                    </v-icon>                  
                  </v-list-item-avatar>              
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                    <v-list-item-subtitle v-text="item.date"></v-list-item-subtitle>                  
                  </v-list-item-content>
                </v-list-item>
                <v-card-text class="py-0">
                  {{item.text}}
                  <v-btn   
                    v-if="false"                                     
                    @click="triggerAction(item.data.button.action)"
                    depressed
                    color="primary"
                    small                    
                  >
                    {{item.data.button.text}}
                  </v-btn>                  
                </v-card-text>
                <v-card-actions>                  
                  <v-btn       
                    v-if="item.data.button"                                 
                    @click="triggerAction(item.data.button.action)"
                    depressed
                    color="primary"
                    small                    
                  >
                    {{item.data.button.text}}
                  </v-btn>
                </v-card-actions>              
              </v-card>
              <v-divider                
                :key="'divider'+item.id"
              ></v-divider>              
            </template>
            <v-list-item v-if="!notify.loaded">
              <v-progress-linear
                color="primary"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-list-item>                      
          </v-list>         
        </template> 
        </v-card-text>       
      </v-card>
		</v-dialog>
  </v-app>
</template>

<script>
//import Vue from 'vue';
import axios from 'axios';
import Home from './components/Home';
import Info from './components/Info';
import Auth from './components/Auth';
import Orders from './components/Orders';
import Cards from './components/Cards';
import Support from './components/Support';
import Discounts from './components/Discounts';
import Referal from './components/Referal';
import Subscription from './components/Subscription';
import Kassa from './components/Kassa';

var menu = {
  Home:{title:'Найти мойку', icon:'mdi-map-marker'},  
  Orders:{title:'История заказов', icon:'mdi-clock', needauth:true},
  Cards:{title:'Способы оплаты', icon:'mdi-credit-card-multiple', needauth:true},
  Discounts:{title:'Скидки', icon:'mdi-sale', needauth:true},
  Subscription:{title:'Абонементы', icon:'mdi-ticket', needauth:true, hidden:true},
  Referal:{title:'Пригласи друга', icon:'mdi-account-group', needauth:true},
  Support:{title:'Техподдержка', icon:'mdi-forum'},
  Info:{title:'Информация', icon:'mdi-information'},
  Kassa:{title:'Кассир', icon:'mdi-cash', needauth:true, hidden:true},
  Auth:{title:'Войти', icon:'mdi-account'},
  Logout:{title:'Выйти', icon:'mdi-logout', needauth:true}  
};

export default {
  name: 'App',
  metaInfo: {
    title: 'Мою Сам'
  }, 
  components: {
    Home,
    Info,
    Auth,
    Orders,
    Cards,
    Support,
    Discounts,
    Referal,
    Subscription,
    Kassa
  },
  data(){
    return {      
      clientversion:'1.0.8',
      actualversion:'1.0.8',
      googlepage:'https://play.google.com/store/apps/details?id=ru.mousam.app',
      applepage:'https://apps.apple.com/app/apple-store/id1552869536',
      server:this.$root.$options.server,
      cordova:this.$root.$options.cordova,
      device:this.$root.$options.device,
      firebase:this.$root.$options.firebase,
      sharing:this.$root.$options.sharing,
      apiUrl:'https://mousam.ru/newapi/',
      drawer: null,    
      menu:menu,      
      title:'Найти мойку',
      user:{
        id:0,        
        token:null,
        phone:null,
        name:'Пользователь',
        info:null             
      },
      page:null,
      dialog:{
        show:false,
        title:'Заголовок',
        message:'Сообщение',
        type:'alert',
        callback:(params) => {
          console.log('Успешно');
          console.log(params);
        }
      },
      notify:{
        loading:false,
        show:false,
        items:null,
        counter:null,
        page:0,
        selected:0,
        lastid:0,
        loaded:false
      },      
      carusel:{
        show:false,
        items:[
          /*{
            id:123,
            title:"Пригласи друзей <br/> и получи скидку",
            text:"Купон на 100 рублей за каждого приглашенного друга",
            img:"https://mousam.ru/upload/iblock/44c/44c70f686c4302d528824ab9082f384a.png",
            bg:"#76b8cc",
            button:{
              text:'Подробнее',
              action:'showPage(\'Referal\')',
            },
            condition:true
          },
          {
            id:124,
            title:"Пригласи друзей <br/> и получи скидку",
            text:"Купон на 100 рублей за каждого приглашенного друга",
            img:"https://mousam.ru/upload/iblock/44c/44c70f686c4302d528824ab9082f384a.png",
            bg:"#76b8cc",
            button:{
              text:'Подробнее',
              action:'showPage(\'Referal\')',
            },
            condition:true
          } */         
        ],
        length:0,
        slide:0
      }      
    }
  },
  methods:{
    showNotifyList(){
      this.notify.show = true;
      if(this.notify.page==0){
        this.loadNotifyList();
      }
    },
    hideNotifyList(){
      this.notify.show = false;
    },
    onNotifyScroll(e){ 
      //console.log(e.target.scrollTop+' '+e.target.offsetHeight+' '+e.target.firstChild.offsetHeight);
      //if(e.target.scrollTop+e.target.offsetHeight>=(this.notify.items.length*73-1)){
      if(e.target.scrollTop+e.target.offsetHeight>=e.target.firstChild.offsetHeight){
        this.loadNotifyList();
      }      
    },
    setNotifyCounter(counter){
      if(counter != this.notify.counter){
        this.notify.page = 0;
        this.loadNotifyList();
      }
      this.notify.counter = counter;
      this.cordova.plugins.notification.badge.set(this.notify.counter);
    },
    loadNotifyCounter(){
      let params = new URLSearchParams();
      params.append('phone', this.user.phone);
      params.append('token', this.user.token);
      params.append('user_id', this.user.id);
      axios({
        method: 'post',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: this.apiUrl+'getnotifycounter.json',        
        data: params,
        withCredentials: true
      })								
      .then(response => {
        if(response.data.error != null){
          console.log(response.data);          
        }else{   
          this.setNotifyCounter(response.data.result.counter);          
          //console.log(response.data.result.counter);  
        }            
      })
      .catch(error => {
        console.log(error);
      });            
    },
    loadNotifyList(){
      if(!this.notify.loading){
        this.notify.loading = true;
        let params = new URLSearchParams();
        params.append('phone', this.user.phone);
        params.append('token', this.user.token);
        if(this.notify.page>0){
          params.append('page', this.notify.page+1);
        }
        axios({
          method: 'post',
          headers: {'Content-Type': 'application/x-www-form-urlencoded'},
          url: this.apiUrl+'getnotifylist.json',        
          data: params,
          withCredentials: true
        })								
        .then(response => {        
          if(typeof response.data.result.items == 'object'){
            if(this.notify.page==0){
              this.notify.items = response.data.result.items;
              this.lastid = response.data.result.items[0].id;
              //console.log('Первый раз');
              this.notify.page++;
              this.notify.loading = false;
              if(response.data.result.items.length < 20){
                this.notify.loaded = true;
              }
            }else if(this.lastid != response.data.result.items[0].id){
              this.notify.items = this.notify.items.concat(response.data.result.items);
              //console.log('Догрузка');            
              this.notify.page++;
              this.notify.loading = false;
            }else{
              this.notify.loaded = true;
            }          
          }          
        })
        .catch(error => {
          console.log(error);
        });
      }
    },    
    markNotifyRead(notify){
      this.notify.selected = notify.id;
      if(!notify.read){
        let params = new URLSearchParams();
        params.append('phone', this.user.phone);
        params.append('token', this.user.token);      
        params.append('id', notify.id);
        axios({
          method: 'post',
          headers: {'Content-Type': 'application/x-www-form-urlencoded'},
          url: this.apiUrl+'marknotifyread.json',          
          data: params,
          withCredentials: true
        })								
        .then(response => {
          if(response.data.error != null){
            console.log(response.data);          
          }else{
            notify.read = true;
            this.setNotifyCounter(this.notify.counter-1);
          }                        
        })
        .catch(error => {
          console.log(error);
        });
      }
    },
    triggerAction(action){
      if(action){
        eval('this.'+action);
        if(this.carusel.show){  
          this.hideCarusel();
        }
        console.log(action);
      }
    },
    nextSlide(){
      this.carusel.slide++;     
      console.log(this.carusel.slide); 
      if(this.carusel.slide == this.carusel.length)
        this.hideCarusel();
    },
    hideCarusel(){
      this.carusel.show = false;
    },
    showCarusel(){
      this.carusel.show = true;
    }, 
    loadCarusel(user){
      let params = new URLSearchParams();
      if(!user){
        params.append('first', true);
      }      
      axios({
        method: 'post',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        //url: 'https://mousam.ru/test/',
        url: this.apiUrl+'loadcarusel.json',
        data: params,
        withCredentials: true
      })								
      .then(response => {      
        this.carusel.items = response.data.result.items;
        if(this.carusel.items.length){
          this.showCarusel();
        }
      })
      .catch(error => {
        console.log(error);        
      });
    },   
    showDialog(props){      
      this.dialog.show = true;
      this.dialog.title = props.title;
      this.dialog.message = props.message;
      if(props.type == 'confirm'){
        this.dialog.type = 'confirm';
      }else{
        this.dialog.type = 'alert';
      }      
      if(typeof props.callback == 'function'){
        this.dialog.callback = props.callback;
      }      
    },    
    showPage(pageName){
      if(pageName == 'Logout'){
        axios
        .get(this.apiUrl+'logout.json')      
        .then(response => {        
          if(response.data.result){
            console.log(response.data);
            this.clearUser();
            this.showPage('Auth');
          }          
        });        
      }else{        
        this.page = pageName;
        this.title = this.menu[pageName].title;
        this.drawer = null;
        this.saveUser({page:this.page});
      }      
      this.hideNotifyList();
    },
    initUserPage(){
      //console.log("init");
      //console.log(this.user.page);      
      if(!this.user.page){
        if(this.user.cards){
          this.showPage('Home');

        }else{
          this.showPage('Cards');
        }              
      }else{              
        this.showPage(this.user.page);   
      }       
      if(this.user.info){        
        if(typeof this.user.info.kassa == 'string' && this.user.info.kassa != null){
          this.menu['Kassa'].hidden = false;
        }
        if(typeof this.user.info.ticket == 'object' && (this.user.info.ticket.id || this.user.info.ticket.canbuy)){
          this.menu['Subscription'].hidden = false;
        }
        if(typeof this.user.info.carusel == 'object' && this.user.info.carusel.items.length>0){
          this.carusel.items = this.user.info.carusel.items;
          this.showCarusel();
        }
      }
      
      this.loadNotifyCounter();
    },
    saveUser(user){
      //console.log("save");      
      this.setUser(user);
      window.localStorage.setItem("userinfo", JSON.stringify(this.user));
    },    
    loadUser(){
      //console.log('load');
      let userinfo = window.localStorage.getItem("userinfo");
      if(userinfo){
         let user = JSON.parse(userinfo);         
         this.setUser(user);
         return user;
      }
      return false;      
    },
    setUser(user){
      //console.log('set');
      if(user.token)
        this.user.token = user.token;
      if(user.phone)
        this.user.phone = user.phone;
      if(user.id)
        this.user.id = user.id;            
      if(user.credit === 0 || user.credit>0)
        this.user.credit = user.credit;
      if(user.cards === 0 || user.cards>0)
        this.user.cards = user.cards;
      if(user.sessid)        
        this.user.sessid = user.sessid;
      if(user.coupon)
        this.user.coupon = user.coupon;
      if(user.page)
        this.user.page = user.page;              
      if(user.info){
        if(typeof this.user.info == 'object' && this.user.info!=null){
          for(let i in user.info){
            this.user.info[i] = user.info[i];
            console.log(i+' '+user.info[i]);
          }          
        }else{
          this.user.info = user.info;
        }
      }
    },
    clearUser(){
        //console.log('clear');
        this.user.id = 0;
        this.user.token = null;
        this.user.phone = null;
        this.user.credit = 0;
        this.user.cards = 0;
        this.user.page = 'Home';
        this.user.sessid = '';
        this.user.coupon = '';   
        this.user.info = {
          ticket:{
            id:0,
            count:0
          }
        };
        this.saveUser(this.user);
    },
    eventHandler(event){
      if(event.name == 'saveUser'){
        this.saveUser(event.props.user);
      }
      if(event.name == 'initUserPage'){
        this.initUserPage();
      }      
      if(event.name == 'authByToken'){
        let user = this.loadUser();
        this.authByToken(user);
      }
    },
    authByToken(user, device){    
      //console.log('authbytoken');
      //console.log(user);
      if(typeof user != 'undefined' && user.token != null && user.phone != null){      
				let params = new URLSearchParams();
				params.append('phone', user.phone);
        params.append('token', user.token);
        //Проверка, если у пользователя есть активный купон
        if(user.coupon){
          params.append('coupon', user.coupon);
        }
				if(typeof device != 'undefined' && device.fcmToken){
					params.append('device_token', device.fcmToken);
					params.append('device_id', device.uuid);
					params.append('device_platform', device.platform);
					params.append('device_model', device.model);	
				}
				axios({
					method: 'post',
					headers: {'Content-Type': 'application/x-www-form-urlencoded'},
					url: this.apiUrl+'authbytoken.json',
					data: params,
					withCredentials: true
				})								
        .then(response => {
          if(response.data.version){
            //this.actualversion = response.data.version;
          }
          if(response.data.error != null){
            //console.log('authbytoken error');
            this.clearUser();                         
          }else{   
            //console.log('authbytoken success');
            this.saveUser(response.data.result);            
          }
          this.initUserPage();          
        })
        .catch(error => {
          console.log(error);
          this.clearUser();          
        });
      }else{
        this.loadCarusel(user);
        this.clearUser();        
        this.initUserPage();        
      }    
    }
  },
  mounted(){    
    console.log('mount');
    //console.log(this.device);
    //console.log(this.firebase);        
    let user = this.loadUser();    //Если самый первый заход, то тут будет false
    
    //console.log('device');    
    if(typeof this.cordova != 'object'){
      this.cordova = {};
    }
    if(typeof this.device == 'object'){    
      console.log('device');
      console.log(this.device);
      if(typeof this.firebase == 'object'){
        //console.log('cordova');
        //console.log(this.cordova);
        this.firebase.getToken((fcmToken) => {          
          this.device.fcmToken = fcmToken;
          this.authByToken(user, this.device);
        }, function(error) {
          this.authByToken(user);
          console.log('firebase fcm error');
          console.log(error);
        });      

        this.firebase.grantPermission((hasPermission) => {
          if(hasPermission){
            console.log('notifications granted');
          }else{
            console.log('notifications denied');
          }
        });
        this.firebase.onMessageReceived((message) => {
          try{
            console.log("onMessageReceived");
            //console.log(message);
            this.loadNotifyCounter();
            if(message.page && this.menu[message.page]){
              this.showPage(message.page);
            }
            if(message.messageType === "notification"){              
              //handleNotificationMessage(message);
            }else{
              //handleDataMessage(message);
            }
            
          }catch(e){
            console.log("Exception in onMessageReceived callback: "+e.message);
          }

        }, function(error) {
          console.log("Failed receiving FirebasePlugin message", error);
        });      
      }else{
        this.device.fcmToken = false;
        this.authByToken(user);
      }    
    }
    else{
      this.device = {};
      this.device.fcmToken = false;
      this.authByToken(user);
    }   
    /*this.cordova.plugins.notification.badge.hasPermission(function (granted) {
        console.log("Разрешение на бейджи:"+granted);
    });*/
  }
};
</script>
<style scoped>
  .v-carousel{
    z-index:100;
    position:fixed;    
  }
  .v-carousel__controls__item {
    width:10px;
    height:10px;
  }
  .v-toolbar{
    z-index:1;
  }
  .close{
    z-index:101;
    position:absolute;
    right:5px;
    top:5px;
  }  
  .bigicon{
    width:100%;
    height:40%;    
  }  
  .bigicon::before{
    font-size:180px;
  }  
</style>
<style>
/*IPhone
  .v-card .v-toolbar, .v-app-bar{
    padding-top:env(safe-area-inset-top);    
    height:calc(env(safe-area-inset-top) + 48px) !important;
  }
  .v-navigation-drawer{
    padding-top:env(safe-area-inset-top);
  }
  .v-main{
    padding-top:calc(env(safe-area-inset-top) + 48px) !important;
  }
  .v-footer{
    padding-bottom:env(safe-area-inset-bottom);
  }
  .bottom-card{
    padding-bottom:env(safe-area-inset-bottom);
  }
  /*end IPhone*/
</style>