<template>
    <v-main>   
	<v-overlay :value="buying">
		<v-card
			color="primary"
			dark
		>
			<v-card-text>
				Ожидание оплаты
				<v-progress-linear
					indeterminate
					color="white"
					class="mb-0"
				>
				</v-progress-linear>
			</v-card-text>
		</v-card>		
	</v-overlay>
	<v-container>
		<div v-if="props.user.info.ticket.id">
		<v-subheader>Действующий абонемент:</v-subheader>
		<v-card			
			dark
			color="warning"
		>
			<v-card-title>
				<v-avatar size="40">
					<v-icon
						dark
						class="orange"
					>
						mdi-ticket
					</v-icon>
				</v-avatar>
				<v-spacer></v-spacer>
				#{{props.user.info.ticket.id}}
			</v-card-title>			
		</v-card>
		<br/>
		<v-simple-table dense>
			<template v-slot:default>
				<tbody>
				<tr>
					<td>Действует до</td>
					<td>{{props.user.info.ticket.end}}</td>
				</tr>
				<tr>
					<td>Моек осталось</td>
					<td>{{props.user.info.ticket.count}}</td>
				</tr>
				</tbody>
			</template>
		</v-simple-table>
		</div>
		<v-skeleton-loader
			v-else
			:loading="loading"
			type="card-heading, list-item-two-line, actions, divider, card-heading, list-item-two-line, actions"
		>				
			<v-row 
				dense 
				v-if="tickets.length>0"
			>
				<v-col cols="12"
					v-if="!agree">
					<v-alert
						color="warning"
						class="ma-0 body-2" 						
						dense
						outlined
						prominent
						border="left"
					>
						<h3>Внимание!</h3> Ознакомьтесь с правилами использования абонементов перед их покупкой:
						<ul>
							<li>Абонементы обмену или возврату не подлежат</li>
							<li>По абонементу может мыться только один автомобиль</li>
							<li>Если по абонементу помоется 2 или более автомобилей, абонемент будет анулирован без возрата его стоимости</li>
						</ul>
						<v-divider
							class="my-2 warning"
						></v-divider>
						<div class="text-center">						
							<v-btn							
								color="primary"							
								small
								depressed
								@click="agree=true"
							>
								Согласен
							</v-btn>
						</div>			
					</v-alert>			
				</v-col>
				<v-subheader>Абонементы в продаже:</v-subheader>
				<v-col cols="12"
					v-for="(item, index) in tickets"
					:key="index"
				>		
					<v-card
						:class="'bg'+index"
						dark
					>
						<v-img
							v-if="item.img"
							class="cover"
							:src="item.img?item.img:''"
						>				
						</v-img>	
						<div
							class="card-content"	
						>
						<v-card-title
							class="pb-0">
							{{item.name}}
						</v-card-title>
						<v-card-text class="text-subtitle-1">{{item.text}}</v-card-text>
						<v-card-actions>
							<h2 class="mx-2">{{item.price}} <v-icon>mdi-currency-rub</v-icon></h2>
							<v-spacer></v-spacer>
							<v-btn
								color="primary"
								depressed	
								@click="buyTicket(item)"	
								:disabled="item.loading || !agree"
								:loading="item.loading"
							>
								Купить
							</v-btn>
						</v-card-actions>					
						</div>
					</v-card>
				</v-col>
			</v-row>
			<v-alert
				v-else
				type="info" 
				class="ma-0 body-2" 
				dense
				outlined
				prominent
				border="left"
			>
				Нет абонементов на продажу
			</v-alert>
		</v-skeleton-loader>
	</v-container>		
    </v-main>
</template>
<script>
import axios from 'axios';
export default {
	name: "Subscription", 	
    props:['props'],
	data() {
        return {
			agree:false,			
			loading:true,
			buying:false,
			tickets:[]
        }
    },
	methods:{
		buyTicket(ticket){			
			if(this.props.user.cards>0 && this.props.user.credit==0){
				this.$emit('show-dialog', 
				{
					title:'Купить абонемент?', 
					//message: 'С активной карты спишется '+ticket.price+'&nbsp;руб. <br/><small>Покупая абонемент вы соглашаетесь правилами использования абонементов.</small>', 
					message: 'С активной карты спишется '+ticket.price+'&nbsp;руб. <br/>', 
					type:'confirm', 
					callback:()=>{     
						this.buying = true;           
						console.log("Покупаем");
						let params = new URLSearchParams();
						params.append('sessid', this.props.user.sessid);
						params.append('phone', this.props.user.phone);
						params.append('token', this.props.user.token);
						params.append('ticketid', ticket.id);
						axios({
							method: 'post',
							headers: {'Content-Type': 'application/x-www-form-urlencoded'},
							url: this.props.apiUrl+'buyticket.json',												
							data:params,
							withCredentials: true
						})				
						.then(response => {
							this.buying = false;							
							if(response.data.error != null){
								if(response.data.error.code == -221){
									this.$emit('show-dialog', 
									{
										title:'Нет карты', 
										message: 'Чтобы воспользоваться сервисом, необходимо привязать пластиковую карту. Привязать карту сейчас?', 
										type:'confirm', 
										callback:()=>{                
											this.$emit('show-page', 'Cards');
										}
									});
								}else{
									this.$emit('show-dialog', {title:'Ошибка', message: response.data.error.message}); 
								}
								console.log(response.data.error);
							}else{
								let user = {
									info:{
										ticket:response.data.result.ticket
									}
								}
								this.$emit('event', {name:'saveUser', props:{user:user}});
								console.log(response.data);
							}               
						})
						.catch(error => {                
							console.log(error);
						});	
					}
				});												
			}else{
				if(this.props.user.cards==0){
					this.$emit('show-dialog', 
					{
						title:'Нет карты', 
						message: 'Чтобы воспользоваться сервисом, необходимо привязать пластиковую карту. Привязать карту сейчас?', 
						type:'confirm', 
						callback:()=>{                
							this.$emit('show-page', 'Cards');
						}
					});
				}
				if(this.props.user.credit>0){
					this.$emit('show-dialog', 
					{
						title:'Имеется долг', 
						message: 'У вас есть непогашенная задолженность в размере '+this.props.user.credit+' руб. Погасить задолженность вы можете в итории заказов. Перейти в историю заказов?', 
						type:'confirm', 
						callback:()=>{
						this.$emit('show-page', 'Orders');                
						}
					});
				}				
			}		
		},
		loadTickets(){
			axios({
				method: 'post',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				url: this.props.apiUrl+'gettickets.json',				
				withCredentials: true
			})
			.then(response => {
				this.loading = false;
				if(response.data.error != null){
					//console.log(response.data.error);
				}else{
					this.tickets = response.data.result.items;		
					//console.log(response.data);
				}
			})
			.catch(error => {
				console.log(error);
				this.loading = false;
			});			
		}
    },    
	mounted(){			
		console.log('tickets');		
		if(typeof this.props.user.info.ticket == 'object' && this.props.user.info.ticket!=null){
			if(this.props.user.info.ticket.id){
				this.loading = false;				
			}
		}
		if(this.loading){
			this.loadTickets();	
		}
//		console.log(this.props.user.info);
	}     
}
</script>
<style scoped>
.bg0{
	background: linear-gradient(0deg, #F57F17, #F9A825);
}
.bg1{
	background: linear-gradient(180deg,#00b09b,#96c93d);
}
.bg2{
	background: radial-gradient(circle 248px at center,#16d9e3 0,#30c7ec 47%,#46aef7 100%);
}
.cover{
	background-size: cover;
	opacity:0.2;
	position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;		
}
.card-content{
	position:relative;
	z-index:1;
}
</style>