<template>
<v-main class="bg">
	<v-container
	fluid
	class="fill-height"	
	>
		<v-row justify="space-around" class="mx-0">
			<v-col cols="12">	
				<!--<v-img class="mx-auto mb-4" max-width="60%" src="./../assets/logo.svg"></v-img>
				<v-spacer />-->
				<v-card v-show="!codeSent" :loading="loading" transition="scale-transition">
					<v-toolbar flat>
						<v-toolbar-title>Авторизация</v-toolbar-title>
					</v-toolbar>					
					<v-card-text class="py-0">				
						<v-form v-model="phoneValid">
							<v-text-field
								v-model="phone"
								:rules="phoneRules"						
								label="Номер телефона"															
								v-mask="'+7 (###) ###-##-##'"
								masked="false" 
								prepend-icon="mdi-phone"
								type="tel"
								required
							></v-text-field>							
							<v-checkbox
								v-model="policy"
								:rules="[v => !!v || 'Вы должны согласиться с условиями']"
								label="Я согласен с условиями"
								required
							>
								<template v-slot:label>									
									<div>
									Я согласен с 
									<a href="javascript:;" @click.stop="loadPolycy();policyshow = true;">условиями</a>
									</div>
								</template>							
							</v-checkbox>							
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-btn @click="sendPhone" :loading="loading" block depressed color="primary">Войти</v-btn>
					</v-card-actions>
				</v-card>	
				<v-card v-show="codeSent" :loading="loading" transition="scale-transition">
					<v-toolbar flat>
						<v-toolbar-title>Проверка кода</v-toolbar-title>
					</v-toolbar>					
					<v-card-text class="py-0">				
						<v-form v-model="codeValid">
							<v-text-field
								v-model="code"								
								:rules="codeRules"						
								label="Подтвердите смс код"								
								v-mask="'####'"
								prepend-icon="mdi-phone"
								type="number"
								required								
							></v-text-field>					
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-btn @click="sendCode" :loading="loading" block depressed color="primary">Войти</v-btn>
					</v-card-actions>
				</v-card>	
				<v-dialog 
					v-model="policyshow" 
					fullscreen
					transition="slide-x-reverse-transition"
				>
					<v-card>
						<v-toolbar dark dense color="primary">
							<v-toolbar-title>Условия</v-toolbar-title>
							<v-spacer></v-spacer>
							<v-toolbar-items>
								<v-btn icon dark @click="policyshow = false">
									<v-icon>mdi-close</v-icon>
								</v-btn>												
							</v-toolbar-items>
						</v-toolbar>					
						<v-card-title class="title">Договор оферта</v-card-title>
						<v-card-text>
							<v-progress-linear
								v-show="!polycytext"
								indeterminate
								color="primary"
								class="mb-0"
							></v-progress-linear>
							<template v-if="polycytext">
								<div v-html="polycytext"></div>
							</template>
						</v-card-text>
						<v-card-actions>						
							<v-btn		
								block				
								depressed
								color="primary"
								@click="policyshow = false"
							>OK</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-col>
		</v-row>
	</v-container>
</v-main>
</template>
<script>
import axios from 'axios';
import {mask} from 'vue-the-mask';
import {Base64} from 'js-base64';

export default {
	name: "Auth", 	
	props:['props'],
	directives: {mask},	
	data() {
		return {
			title:"Вход",
			phone:'+7',	
			code:'',
			policy:false,	
			policyshow:false,
			polycytext:``,
			loading:false,						
			phoneValid:false,
			codeValid:false,
			phoneRules: [
				v => !!v || 'Укажите номер мобильного',
				//v => v.length 
				v => /\+\d{1,3}\s?\(\d{3}\)\s?\d{3}(-\d{2}){2}/.test(v) || 'Телефон указан неверно',
			],
			codeRules: [
				v => !!v || 'Укажите код пришедший по смс',
				v => v.length == 4 || 'Смс код состоит из 4 цифр',
			],
			codeSent:false
		}
	},
	methods:{
		sendPhone(){
			if(this.phoneValid){
				this.loading = true;
				let phone = this.phone.replace(' ','').replace(' ','').replace('(','').replace(')','').replace('-','').replace('-','').replace('+','').replace('_','');				
				let secret = this.generateSecret(128);
				let json_params = {
					phone:phone,
					secret:secret
				};
				let base = Base64.encode(JSON.stringify(json_params));
				let value = '';
				for(let i = 0;i<=127;i++){
					value += secret.charAt(i) +base.charAt(i);
				}
				let params = new URLSearchParams();
				params.append('secret', value+base.slice(128));
				axios({
					method: 'post',
					headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
					url: this.props.apiUrl+'sendphone.json',					
					data: params,
					withCredentials: true 
				})
				.then(response => {
					//console.log(response);
					if(response.data.error != null){
						if(response.data.error.code != 201){
							this.$emit('show-dialog', {title:'Ошибка', message: response.data.error.message});
						}else{
							this.$emit('show-dialog', {title:'Ошибка', message: 'Возможно сервис перегружен. Попробуйте позже'});
						}
					}else{
						if(response.data.result.id>0){							
							this.phone = phone;
							this.props.user.sessid = response.data.result.sessid;
						}
						this.codeSent = true;
					}
					this.loading = false;
				})
				.catch(error => {
					console.log(error);
					this.loading = false;
				});
			}
		},
		sendCode(){
			if(this.codeValid){
				this.loading = true;
				let params = new URLSearchParams();
				params.append('phone', this.phone);
				params.append('code', this.code);
				params.append('sessid', this.props.user.sessid);
				if(this.props.device.fcmToken){
					params.append('device_token', this.props.device.fcmToken);
					params.append('device_id', this.props.device.uuid);
					params.append('device_platform', this.props.device.platform);
					params.append('device_model', this.props.device.model);	
				}
				axios({
					method: 'post',
					headers: {'Content-Type': 'application/x-www-form-urlencoded'},
					url: this.props.apiUrl+'auth.json',
					data: params,
					withCredentials: true
				})								
				.then(response => {
					console.log(response.data);										
					if(response.data.error != null){
						this.$emit('show-dialog', {title:'Ошибка', message: response.data.error.message});
					}else if(response.data.result.id){		
						let user = 	response.data.result;								
						user.page = 'Home';
						this.$emit('event', {name:'saveUser', props:{user:user}});
						this.$emit('event', {name:'initUserPage'});
						this.codeSent = false;
					}else{
						this.$emit('show-dialog', {title:'Ошибка', message: 'Не удалось авторизоваться. Возможно, сервис перегружен. Попробуйте позже'});
					}
					this.loading = false;
				})
				.catch(error => {
					console.log(error);
					this.loading = false;
				});
			}
		},
		generateSecret(length) {
			let result = '';
			let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			let charactersLength = characters.length;
			for (let i = 0; i < length; i++ ) {
				result += characters.charAt(Math.floor(Math.random() * charactersLength));
			}
			return result;
		},		
		loadPolycy(){
			if(this.polycytext.length==0){
				console.log('Загружаем текст');
				axios
				.get('https://mousam.ru/oferta.php')				
				.then(response => {
					this.polycytext = response.data
				});
			}
		}
	},
	mounted(){
		console.log('auth');
		console.log(this.props.device);
	}
}
</script>
<style scoped>
	.bg{
		background:url(./../assets/bg.jpg);
		background-size: cover;
	}
</style>